import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Chip,
    Typography,
    Checkbox,
    Container,
    Button,
} from '@mui/material';

import { DeleteOutline } from '@mui/icons-material';
import { supabase } from '../../supabaseClient';
import Navbar from '../../components/common/Navbar';
import useCompanyData from '../../hooks/useCompanyData'

function SendActivity() {
    const company = useCompanyData();
    const [activities, setActivities] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState([]);

    const fetchActivities = async () => {
        if(!company){
            return;
        }
        console.log(company.id)
        const { data, error } = await supabase
            .from('activities')
            .select('*')
            .eq('company', company?.id);
        if (error) {
            console.error(error);
        } else {
            setActivities(data);
        }
    };

    const handleDeleteActivity = async (activityId) => {
        // TODO: Lógica para eliminar una actividad
    };

    const handleCheckboxChange = (activityId) => {
        const isSelected = selectedActivities.includes(activityId);

        if (isSelected) {
            setSelectedActivities((prevSelected) =>
                prevSelected.filter((id) => id !== activityId)
            );
        } else {
            setSelectedActivities((prevSelected) => [...prevSelected, activityId]);
        }
    };

    useEffect(() => {
        fetchActivities();
    }, [company]);

    if(!company) return <></>

    return (
        <>
            <Navbar />
            <Container>

                <Typography variant="h5" gutterBottom>
                    Selecciona las actividades
                </Typography>

                <Button variant="contained" color="primary" component={Link} to={`/admin/activities/create?company=${company.id}`}>
                    Registrar actividad
                </Button>

                <TableContainer component={Paper} sx={{ 'marginTop': '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Descripción de la actividad</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activities.map((activity) => (
                                <TableRow key={activity.id} sx={{background: selectedActivities.includes(activity.id) ? '#f9f9f9' : 'white'}}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedActivities.includes(activity.id)}
                                            onChange={() => handleCheckboxChange(activity.id)}
                                        />
                                    </TableCell>
                                    <TableCell>{activity.name}</TableCell>
                                    <TableCell>{activity.description}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteActivity(activity.id)}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {selectedActivities.length > 0 && <Chip
                    label="Abrir formulario"
                    color="primary"
                    component="a"
                    target="__blank"
                    href={`${window.location.origin}/activities?company=${company?.id}&activities=${selectedActivities.join('-')}`}
                    clickable
                    sx={{ mt: 2, p: 3, fontSize: '1.5rem' }}
                />}
            </Container>


        </>
    );
}

export default SendActivity;
