import React from 'react';
import { Typography, Grid, Card, CardContent } from "@mui/material";
import { FaCircleQuestion } from 'react-icons/fa6';
import { RiTodoFill } from 'react-icons/ri';

import recomendationsData from '../../../components/lencioni/data/recomendations'

const RecomendationGroup = ({ group, title, category }) => {

    return (
        <>
            <Typography variant='h5'>{title}</Typography>
            <Grid container spacing={3}>
                {group.map((item, index) => (
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ mb: 3, minHeight: '220px', background: getColor(category?.score, true) }}>
                            <CardContent>
                                <Typography variant='h6'>
                                    <FaCircleQuestion color="#1976d2" fontSize="20" />
                                    <b> Qué hacer</b>
                                </Typography>

                                <Typography>{item.todo}</Typography>
                                <Typography  sx={{mt: 3}} variant='h6'>
                                    <RiTodoFill color="#1976d2" fontSize="20" />
                                    <b> Cómo hacerlo</b>
                                </Typography>
                                <Typography>{item.howto}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

const getColor = (score, soft = false) => {
    if (score >= 0 && score <= 3.24) {
        return soft ? "#ffdbdb" : "red";
    } else if (score >= 3.25 && score <= 3.74) {
        return  soft ? "#fff8bb" : "yellow";
    } else {
        return  soft ? "#c7ffc9" : "green";
    }
};

const Recomendations = ({ categories }) => {
    const [allRecomendations, setAllRecomendations] = React.useState([])



    React.useEffect(() => {
        const recomendations = categories.map(category => {
            const color = getColor(category.score)
            const result = recomendationsData[category.name]
            return result[color]
        });
        setAllRecomendations(Object.values(recomendations))
        console.log(recomendations)
    }, [categories])

    return (
        <>
            <Typography variant="h3" color='primary' align='center'>Recomendaciónes</Typography>

            {allRecomendations.map((recommendation, index) =>
                <>
                    {index === 0 && (
                        <Typography variant="h2" color='primary'>Confianza</Typography>
                    )}
                    {index === 1 && (
                        <Typography variant="h2" color='primary'>Conflicto</Typography>
                    )}
                    {index === 2 && (
                        <Typography variant="h2" color='primary'>Compromiso</Typography>
                    )}
                    {index === 3 && (
                        <Typography variant="h2" color='primary'>Responsabilidad</Typography>
                    )}
                    {index === 4 && (
                        <Typography variant="h2" color='primary'>Resultados</Typography>
                    )}

                    <RecomendationGroup
                        title='Autoconocimiento del líder'
                        group={recommendation.leader_selfknowledge}
                        category={categories[index]} />
                    <RecomendationGroup
                        title='Relación con su equipo en general'
                        group={recommendation.leader_relation}
                        category={categories[index]} />
                    <RecomendationGroup
                        title='Acciones particulares con cada colaborador'
                        group={recommendation.team_actions}
                        category={categories[index]} />
                </>)}


        </>
    );
};

export default Recomendations;

