import React from 'react';
import { TextField, Button } from '@mui/material';

const CanvasFooter = ({ newElement, setNewElement, addNewElement }) => {
  return (
    <div>
      <TextField
        label="Prompt"
        value={newElement.prompt}
        onChange={(e) => setNewElement({ ...newElement, prompt: e.target.value })}
      />
      <Button variant="contained" color="primary" onClick={addNewElement}>
        Add Element
      </Button>
    </div>
  );
};

export default CanvasFooter;
