// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    border: none;
}
td {
    font-size: 30px;
    border: none;
}
.legend {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.legendRow{
    display: inline-block;
    padding: 10px;
}
.number {
    font-size: 30px;
}
.text {
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/lencioni/report/Legend.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB","sourcesContent":["table {\n    border: none;\n}\ntd {\n    font-size: 30px;\n    border: none;\n}\n.legend {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.legendRow{\n    display: inline-block;\n    padding: 10px;\n}\n.number {\n    font-size: 30px;\n}\n.text {\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
