import React, { useEffect, useState } from "react";
import { supabase } from "../../../supabaseClient";
import { Container, Grid, Typography, Alert } from '@mui/material';

import Pyramid from "../../../components/lencioni/report/Pyramid";
import Bar from "../../../components/lencioni/report/Bar";
import Summary from "../../../components/lencioni/report/Summary";
import Recomendations from '../../../components/lencioni/report/Recomendations'

import Item from "../../../components/lencioni/report/Item";
import Legend from "../../../components/lencioni/report/Legend"

import questions from "../../../components/lencioni/data/questions";

const QuestionsGroup = ({ title, category, records }) => {
    return <>
        <Typography variant="h4" color='primary'>{title}</Typography>
        <Legend/>
        <br></br>
        {questions
            .filter(q => q.category === category)
            .map((q, i) => (
                <Item
                    key={i}
                    question={q.text}
                    score={records[q.index - 1]}
                    index={q.index}
                />
            ))
        }
    </>
}


const Report = () => {
    const [records, setRecords] = useState(null);
    const [averageRecords, setAverageRecords] = useState([]);
    const [categories, setCategories] = useState([]);
    const [empty, setEmpty] = useState(false);

    const fetchRecords = async () => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const { data, error } = await supabase
            .from("lencioni_records")
            .select("*")
            .eq("team", urlParams.get('team'));

        if (error) {
            console.error(error);
        } else {

            if (data && data.length > 0){   
                setRecords(data)
                setAverageRecords(averageScores(data));
            }else{
                setEmpty(true)
            }

        }
    };

    const averageScores = (records) => {
        const scoresList = records.map(record => JSON.parse(record.scores));
        const averageList = scoresList.reduce((acc, scores) => {
            return acc.map((score, index) => (score + scores[index]) / 2);
        });
        return averageList;
    };

    const averageCategoryScores = (records, category) => {
        const filteredQuestions = questions.filter(q => q.category === category);
        const questionIndices = filteredQuestions.map(q => q.index - 1);
        const categoryRecords = records.map(record => {
            const scores = JSON.parse(record.scores);
            return questionIndices.map(index => scores[index]);
        });
        const averageRecords = categoryRecords.map(scores => {
            const sum = scores.reduce((acc, score) => acc + score, 0);
            return sum / scores.length;
        }).reduce((acc, score) => acc + score, 0) / categoryRecords.length;

        return averageRecords;
        
    };

    const getAllCategoriesScores = (records) => {
        const trustAverage = averageCategoryScores(records, 'trust');
        const conflictAverage = averageCategoryScores(records, 'conflict');
        const engagementAverage = averageCategoryScores(records, 'engagement');
        const responsabilityAverage = averageCategoryScores(records, 'responsability');
        const resultsAverage = averageCategoryScores(records, 'results');


        const categories = [
            { displayName: "Confianza", name: "trust", score: trustAverage },
            { displayName: "Conflicto", name: "conflict", score: conflictAverage },
            { displayName: "Compromiso", name: "engagement", score: engagementAverage },
            { displayName: "Responsabilidad", name: "responsability", score: responsabilityAverage },
            { displayName: "Resultados", name: "results", score: resultsAverage }
        ];

        setCategories(categories)
        console.log(categories)
    };
    
    

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        if (!records) return
        getAllCategoriesScores(records)
    }, [records]);

    return (
        <>

            {empty ? <Container sx={{'display':'flex', 'justifyContent':'center', 'alignItems':'center', 'height':'100vh'}}>
                <Typography variant="h4"> Nadie ha respondido aún</Typography>
            </Container> :

            <Container>

                <Pyramid categories={categories}/>

                <Summary categories={categories}/>

                <Grid container spacing={6}>
                <Grid item xs={6}>
                        <QuestionsGroup title="Confianza" category="trust" records={averageRecords} />
                    </Grid>
                    <Grid item xs={6}>
                        <QuestionsGroup title="Conflicto" category="conflict" records={averageRecords} />
                    </Grid>
                    <Grid item xs={6}>
                        <QuestionsGroup title="Compromiso" category="engagement" records={averageRecords} />
                    </Grid>
                    <Grid item xs={6}>
                        <QuestionsGroup title="Responsabilidad" category="responsability" records={averageRecords} />
                    </Grid>
                    <Grid item xs={6}>
                        <QuestionsGroup title="Resultados" category="results" records={averageRecords} />
                    </Grid>
                </Grid>

                <Recomendations categories={categories}/>

            </Container>

            }


        </>
    );
};

export default Report;