import React, {useState} from 'react';
import { Card, CardContent } from '@mui/material';

import CanvasFooter from './CanvasFooter';
import CanvasElement from './CanvasElement';

const Canvas = ({ canvasElements, setCanvasElements }) => {
  const [selectedElementId, setSelectedElementId] = useState(null);

  const [newElement, setNewElement] = React.useState({
    prompt: '',
  });

  const handleElementClick = (elementId) => {
    setSelectedElementId(elementId);
  };


  const addNewElement = () => {
    // Lógica para agregar un nuevo elemento al lienzo
  };


  return (
    <Card>
      <CardContent>
        <div style={{ position: 'relative', width: '1280px', height: '720px', border: '1px solid #ccc' }}>
          {canvasElements.map((element) => (
            <CanvasElement key={element.id} element={element} onClick={() => handleElementClick(element.id)} isSelected={selectedElementId === element.id} setCanvasElements={setCanvasElements} />
          ))}
        </div>
        <div style={{ marginTop: '20px' }}>
          <CanvasFooter
            newElement={newElement}
            setNewElement={setNewElement}
            addNewElement={addNewElement}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default Canvas;