import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import {  Container, Button, Tabs, Tab, Box,AppBar } from '@mui/material';

import { HiPresentationChartBar } from 'react-icons/hi';

import ActivityInput from '../../components/Activities/ActivityInput';
import ImageAnalisis from '../../components/Activities/ImageAnalisis';
import Recomendations from '../../components/Activities/Recomendations';
import Photos from '../../components/Activities/Photos';

import Navbar from '../../components/common/Navbar';
import useCompanyData from '../../hooks/useCompanyData';

import { supabase, host } from '../../supabaseClient'
//import { exportToExcel } from "react-json-to-excel";

function Activities() {
  const companyData = useCompanyData()
  const [activityResults, setActivityResults] = useState({});
  const [activities, setActivities] = useState([]);
  const [images, setImages] = useState([]);

  const [recomendations, setRecomendations] = useState('');

  const [value, setValue] = React.useState(0);
  
  const [analisisImages, setAnalisisImages] = useState([]);
  const [result, setResult] = useState('');


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const activityIds = urlParams.get('activities').split('-');

    const fetchActivities = async () => {
      try {
        const { data, error } = await supabase
          .from('activities')
          .select('*')
          .in('id', activityIds)

        if (error) {
          console.error(error);
        } else {
          setActivities(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivities();
  }, []);

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  }

  const uploadImages = async () => {
    const imageUrls = [];

    for (let i = 0; i < images.length; i++) {
      const { data, error } = await supabase.storage
        .from('ppt_images')
        .upload(
          Date.now() + '.png',
          images[i]

        );
      console.log(data)
      if (error) {
        console.error(error);
        return;
      }

      imageUrls.push(`${host}/storage/v1/object/public/ppt_images/${data.path}`);
    }

    return imageUrls;
  };


  async function handleDownload() {
    const imageUrls = await uploadImages();
    console.log(imageUrls)


    const activityData = activities.map(activity => {
      return {
        name: activity.name,
        content: activityResults[activity.id]
      }
    })

    const content = {
      activities: activityData,
      recomendations: recomendations,
      images: imageUrls,
      transcription: result
    };

    console.log(content)

    const contentString = JSON.stringify(content);
    const url = `${process.env.REACT_APP_BASE_URL}/activities/ppt?content=${encodeURIComponent(contentString)}&company=${companyData.id}`;

    try {
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
    }

  }

  /*function handleDownloadExel() {
    const tableData = activities.map(activity => {
      return {
        name: activity.name,
        result: activityResults[activity.id]
      }
    })
    console.log(activityResults)

    exportToExcel(tableData, 'actividad')
  }*/


  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
           {children}
          </Box>
        )}
      </div>
    );
  }
  

  if (!companyData) return <></>

  return (
    <>
      <Navbar />

      <Container>
        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Actividades" {...a11yProps(0)} />
              <Tab label="Recomendaciones" {...a11yProps(1)} />
              <Tab label="Análisis de Imágenes" {...a11yProps(2)} />
              <Tab label="Fotos" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={'x'}>
                {activities.map((activity, index) => (
                  <ActivityInput
                    key={index}
                    id={activity.id}
                    name={activity.name}
                    description={activity.description}
                    company={companyData}
                    result={activityResults[activity.id]}
                    setResult={(result) =>
                      setActivityResults((prevResults) => ({
                        ...prevResults,
                        [activity.id]: result,
                      }))
                    }
                  />
                ))}
            </TabPanel>
            <TabPanel value={value} index={1} dir={'x'}>
              {companyData.id !== 5 && <Recomendations result={recomendations} setResult={setRecomendations} company={companyData}/>}
            </TabPanel>
            <TabPanel value={value} index={2} dir={'x'}>
                {companyData.id !== 5 && <>
                
                  <ImageAnalisis images={analisisImages} setImages={setAnalisisImages} result={result} setResult={setResult} />
                </>}
            </TabPanel>
            <TabPanel value={value} index={3} dir={'x'}>
                {companyData.id !== 5 && <Photos images={images} setImages={setImages} />}
            </TabPanel>
            

          </SwipeableViews>
        </Box>


            {Object.values(activityResults).every(Boolean) && (
              <Button
                sx={{ fontSize: '1rem', margin: 3 }}
                onClick={handleDownload}
                variant="contained"
                color="success"
                startIcon={<HiPresentationChartBar />}
              >
                Descargar como Presentación
              </Button>

            )}
{/* 
            <Button
              sx={{ fontSize: '1rem', margin: 3 }}
              onClick={handleDownloadExel}
              variant="contained"
              color="success"
              startIcon={<BsFileEarmarkSpreadsheetFill />}>

              Descargar como Exel
            </Button> */}


      </Container>
    </>
  );
}

export default Activities;
