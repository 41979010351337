import React, { useState } from 'react';
import { Card, CardContent, Tabs, Tab, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { IoIosText, IoIosDocument, IoIosImage } from 'react-icons/io';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const Sidebar = ({ onAddItem }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleItemClick = (item) => {
    onAddItem(item);
  };

  const renderTabContent = (tabItems) => (
    <div>
      <List>
        {tabItems.map((item, index) => (
          <ListItem key={index} button onClick={() => handleItemClick(item)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const basicTab = [
    { name: 'title', label: 'titulo', x:100, y: 20, width: 600, height: 100,  icon: <IoIosText /> },
    { name: 'content', label: 'Cuerpo', x:100, y: 100, width: 600, height: 400,  icon: <IoIosDocument /> },
    { name: 'image', label: 'Imagen', x:0, y:0, width: 100, height: 100,  icon: <IoIosImage /> },
  ];

  const customTab = [
    { label: 'Item A', icon: <InboxIcon /> },
    { label: 'Item B', icon: <MailIcon /> },
  ];

  return (
    <Card>
      <CardContent>
        <Tabs value={tabValue} onChange={handleChangeTab} orientation="horizontal">
          <Tab label="Basic" />
          <Tab label="Custom" />
        </Tabs>

        {tabValue === 0 && renderTabContent(basicTab)}
        {tabValue === 1 && renderTabContent(customTab)}
      </CardContent>
    </Card>
  );
};

export default Sidebar;
