import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    TextField,
    Button,
    Card,
    CardContent,
    Grid
} from '@mui/material';

import { supabase } from '../../supabaseClient';

import DashboardAppBar from '../../components/admin/AppBar';

const CreateActivity = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleCreateActivity = async () => {
        try {
            const { data, error } = await supabase
                .from('activities')
                .insert([{ name, description }]);
            if (error) {
                console.error(error);
            } else {
                console.log(data);
                navigate(-1);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container>
            <DashboardAppBar title={'Crear Actividad'} />
            <br /><br /><br /><br /><br /><br />
            <Card>
                <CardContent>
                    <TextField
                        label="Nombre"
                        value={name}
                        onChange={handleNameChange}
                        fullWidth
                        sx={{ marginBottom: '10px' }}
                    />
                    <TextField
                        label="Descripción"
                        value={description}
                        onChange={handleDescriptionChange}
                        fullWidth
                        sx={{ marginBottom: '10px' }}
                    />
                    <Button variant="contained" color="primary" onClick={handleCreateActivity}>
                        Crear Actividad
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default CreateActivity;
