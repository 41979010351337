import { useEffect, useState } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { supabase } from '../../supabaseClient';

function Navbar() {
    const [companyData, setCompanyData] = useState(null);
    const location = useLocation();
    const companyId = new URLSearchParams(location.search).get("company");

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const { data, error } = await supabase
                    .from('companies')
                    .select('*')
                    .eq('id', companyId)
                    .single();

                if (error) {
                    console.error('Error fetching company data:', error.message);
                } else {
                    setCompanyData(data);
                }
            } catch (error) {
                console.error('Error fetching company data:', error.message);
            }
        };

        if (companyId) {
            fetchCompanyData();
        }
    }, [companyId]);

    return (
        <>
            <AppBar
                sx={{
                    background: companyData ? companyData.color_background : 'white',
                    height: '80px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                position="static"
            >
                <Toolbar>
                    <img
                        sx={{ borderRadius: '0px 60px 0px 0px' }}
                        height={'50px'}
                        src={companyData?.logo_url}
                    />
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Navbar;
