import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Chip,
    Button
} from '@mui/material';


import { DeleteOutline } from '@mui/icons-material'
import { IoBusiness } from "react-icons/io5";

import { supabase } from '../../../supabaseClient';
import DashboardAppBar from '../../../components/admin/AppBar';
import DashboardContainer from '../../../components/admin/DashboardContainer';

function Companies() {
    const [companies, setCompanies] = useState([]);

    const tools = [
        { label: 'Actividades', url: '/activities/send' }, // TODO: aqui va un panel con todos las actividades, una opcion para crear una, y un selector de actividaes que genera un link
        { label: 'Recomendador de proyectos', url: '/casestudies' },
    ];

    async function fetchCompanies() {
        const { data, error } = await supabase
            .from('companies')
            .select('*');
        if (error) {
            console.error(error);
        } else {
            setCompanies(data);
        }
    }

    const handleDeleteCompany = async (teamId) => {
        const confirmed = window.confirm('¿Estás seguro de que quieres eliminar esta empresa?');
        if (confirmed) {
            try {
                const { data, error } = await supabase
                    .from('companies')
                    .delete()
                    .eq('id', teamId);
                if (error) return console.error(error);

                fetchCompanies();
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    return (
        <>

            <DashboardAppBar title={'Clientes'} />

            <DashboardContainer>

                <TableContainer component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Borrar</TableCell>
                                <TableCell>Herramientas</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies.map((company, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{ width: '60px', background: company.color_background }}>
                                        <img style={{ height: '40px' }} src={company.logo_url}></img>
                                    </TableCell>
                                    <TableCell>
                                        {company.name}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteCompany(company.id)}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        {tools.map((tool, index) => (
                                            <Chip
                                                key={index}
                                                label={tool.label}
                                                onClick={() => window.open(window.location.origin + tool.url + '?company=' + company.id, '_blank', 'noopener,noreferrer')}
                                            />
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Link to="/admin/companies/create" style={{ textDecoration: 'none' }}>
                    <Button sx={{ mt: 2 }} variant='contained' startIcon={<IoBusiness />}>
                        Registrar cliente
                    </Button>
                </Link>

            </DashboardContainer>
        </>
    );
}

export default Companies;

