import { MdOutlineReportProblem } from 'react-icons/md';
import { MdLightbulb } from "react-icons/md";
import { Grid, Typography, Card, CardContent } from '@mui/material';

function SearchResult({ searchResult }) {
    const handleCardClick = () => {
        window.open(searchResult.webLink, '_blank');
    };

    return (
        <>
            <Grid item xs={4}>
                <Card sx={{ cursor: 'pointer' }} onClick={handleCardClick} variant="outlined">
                    <CardContent className='card-body' sx={{ padding: '0px!important', background: '#070f26', color: 'white' }}>
                        {/* <CardMedia
                            component="img"
                            alt={searchResult.title}
                            height="140"
                            image={searchResult.imageUrl}
                        /> */}
                        <Typography className='title' variant="h6" component="div" sx={{ padding: '20px' }}>
                            {searchResult.title}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h6" component="div">
                            <MdOutlineReportProblem /> Problema:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {searchResult.problem}
                        </Typography>
                        <Typography variant="h6" component="div">
                            <MdLightbulb />Solución:
                        </Typography>
                        <Typography variant="body2" component="text.secondary">
                            {searchResult.solution}
                        </Typography>
                        {/* <img src="https://flagsapi.com/es/flat/256.png" />
                        <Typography variant="h6" component="span">
                            Implementada en España
                        </Typography> */}

                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default SearchResult