import './Legend.css'

const Legend = () => {
    return <>
        <table>
            <tbody>
                {["Nunca", "Raramente", "Algunas veces", "Usualmente", "Siempre"].map((text, index) => (
                    <tr className="legendRow" key={index}>
                        <td>
                            <div>
                                <div class="legend text">{text}</div>
                                <div class="legend number">{index + 1}</div>
                            </div>
                        </td>
                    </tr>
                ))} 
            </tbody>
        </table>
    </>

}
export default Legend