import React from 'react';
import { Grid, Container, List, ListItem, IconButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';  // Importa el componente Link de React Router

const Sidebar = ({ children }) => {
    const links = [
        { link: '/admin/companies', label: 'Empresas' },
        { link: '/admin/lencioni', label: 'Lencioni' },
    ];

    return (
        <Grid container>
            <Grid item xs={2}>
                <List sx={{ width: '100%', maxWidth: 360, mt: 8 }}>
                    {links.map((link, index) => (
                        <Link to={link.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItem
                                key={index}
                                disableGutters
                            >
                                {/* Usa el componente Link para hacer el enlace accionable */}

                                <ListItemText primary={link.label} />

                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Grid>

            <Grid item xs={10} sx={{mt: 10}}>
                <Container>
                    {children}
                </Container>
            </Grid>
        </Grid>
    );
}

export default Sidebar;
