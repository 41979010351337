import Tip from './Tip'

function TeachingPitch({ tips }) {

    return (
        <>
            {tips.map((tip) => (
                <Tip key={tip} tip={tip} />
            ))}
        </>
    );
}

export default TeachingPitch