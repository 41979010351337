import React from 'react';
import Draggable from 'react-draggable';

const CanvasElement = ({ element, setCanvasElements, isSelected, onClick }) => {
  const handleDrag = (id, e, ui) => {
    setCanvasElements((prevElements) =>
      prevElements.map((el) => (el.id === id ? { ...el, x: ui.x, y: ui.y } : el))
    );
  };

  return (
    <Draggable key={element.id} defaultPosition={{ x: element.x, y: element.y }} onDrag={(e, ui) => handleDrag(element.id, e, ui)}>
      <div
        style={{
          border: isSelected ? '2px solid blue' : '1px solid #000',
          borderRadius: '5px',
          background: '#fff',
          cursor: 'move',
          textAlign: 'center',
          padding: '10px',
          left: element.x + 'px',
          top: element.y + 'px',
          width: element.width + 'px',
          height: element.height + 'px',
        }}
        onClick={onClick}
      >
        <textarea 
            style={{
                border: 'none',
                outline: 'none',
                fontFamily: 'sans-serif',
                fontSize: '30px',
                textAlign: 'center',
                width: '100%',
                height: '100%',
                resize: 'none'
            }}
        
        placeholder={element.label}></textarea>
      </div>
    </Draggable>
  );
};

export default CanvasElement;
