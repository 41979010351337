import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function Photos({images, setImages}) {
    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const handleImageChange = (e) => {
        const selectedImages = Array.from(e.target.files)
        setImages(selectedImages);
        setShowResult(false);
    };

    const handleAnalyze = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setShowResult(true);
        }, 8000);
    };

    return (
        <div>
            <Card sx={{ mt:5, minWidth: 275 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Fotos de la actividad
                    </Typography>
                    <input type="file" onChange={handleImageChange} accept="image/*" multiple />
                    <br/>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={  URL.createObjectURL(image)}
                            alt={`Previsualización ${index + 1}`}
                            style={{ maxWidth: '100%', height: '150px', marginTop: '10px' }}
                        />
                    ))}
                </CardContent>
            </Card>
        </div>
    );
}
