import Bar from './Bar';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Legend from './Legend'

const styles = {
    gridItem: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '25px',
        borderBottom: 'solid 1px black',
    }
};


const Summary = ({categories}) => {
    return (<>
        <Typography variant="h4" color="primary">LOS CINCO FUNDAMENTOS</Typography>
        <Grid container>
            <Grid style={styles.gridItem} item xs={12}>
                <Legend/>
            </Grid>
            <Grid style={styles.gridItem} item xs={0}>
            </Grid>
            {categories && categories.map(category => (<>
                <Grid style={styles.gridItem} item xs={6} key={category.name}>
                    <Typography variant="h6">{category.displayName}</Typography>
                </Grid>
                <Grid style={styles.gridItem} item xs={6} key={category.name + '_2'}>
                    <Bar score={category.score} isLeft={false} />
                </Grid>

                </>
            ))}

        </Grid>
    </>
    );
};

export default Summary;
