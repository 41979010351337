import React, { useState, useEffect } from 'react';
import { TextField, Button, Card, Typography, Box, Skeleton, Grid } from '@mui/material';

import { GrMicrophone } from 'react-icons/gr';
import { HiMicrophone } from 'react-icons/hi';

import { FileUploader } from "react-drag-drop-files";

import axios from 'axios'

const fileTypes = ["JPG", "PNG", "WEBP"];

function Recomendations({result, setResult, company}) {
  
  const [show, setShow] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [listening, setListening] = useState(false);
  const [micIcon, setMicIcon] = useState(<HiMicrophone />);
  const [loading, setLoading] = useState(false);
  const [recomendations, setRecomendations] = useState('');
  
  const [files, setFiles] = useState({});


  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  }

  const handleRecomendationsChange = (event) => {
    setRecomendations(event.target.value);
  };

  const handleResultChange = (event) => {
    setResult(event.target.value);
  };

  const handleChange = (files) => {
    console.log(files)
    setFiles(files);
  };

  const handleMicClick = () => {
    if (listening) {
      setMicIcon(<HiMicrophone />);
      recognition.stop();
    } else {
      setMicIcon(<GrMicrophone />);
      recognition.start();
    }
    setListening(!listening);
  };

  const handleGenerate = async () => {
    setLoading(true)

    let data = { recomendations }
    // const images = [];
    // for (let i = 0; i < files.length; i++) {
    //   let file = files[i];
    //   let imageBase64 = await getBase64(file);
    //   images.push(imageBase64);
    // }
    // data.images = images;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/activities/recomendations`, data)
      .then((response) => {
        console.log(response.data);
        setResult(response.data);
        //setRecomendations(response.data);
        setShow(true);
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error al enviar las recomendaciones:', error);
        setLoading(false)
      });
  };

  useEffect(() => {
    if (transcript) {
      setRecomendations(transcript);
    }
  }, [transcript]);

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();
  recognition.lang = 'es-CL';

    recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    setTranscript(prevTranscript => prevTranscript + ' '+ transcript);
  };


  return (
    <Card sx={{mt:4}}>
      <Grid item xs={12} lg={12} sx={{ background: company.color_primary, margin: '0px!important' }}>
          <Typography variant="h5" sx={{ p: 3, color: 'white' }}>Recomendaciones</Typography>
      </Grid>
      <TextField
        value={recomendations}
        onChange={handleRecomendationsChange}
        placeholder="Escribe tus recomendaciones aquí o usa el micrófono"
        multiline
        rows={10}
        fullWidth
        variant="outlined"
        style={{
          borderRadius: '10px',
          padding: '10px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px',
        }}
      />

      <Button onClick={handleMicClick} sx={{ fontSize: '50px', color: '#2196f3', mr: 2 }}>
        {micIcon}
      </Button>
      <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#2196f3' }}>
        {listening ? 'Escuchando...' : ''}
      </span>
      <Button
        onClick={handleGenerate}
        variant="contained"
      >
        Generar
      </Button>

      {loading && <Box>
          <Skeleton width='100%' height='40px' animation={'wave'}/>
          <Skeleton width='100%' height='40px' animation={'wave'}/>
          <Skeleton width='100%' height='40px' animation={'wave'}/>
          <Skeleton width='100%' height='40px' animation={'wave'}/>
          <Skeleton width='100%' height='40px' animation={'wave'}/>
          <Skeleton width='100%' height='40px' animation={'wave'}/>
      </Box>}

        <Card sx={{ mt: 3 }}>
          <TextField
            value={result}
            onChange={handleResultChange}
            multiline
            rows={10}
            fullWidth
            variant="outlined"
  
          />

        </Card>
  

      {/* <FileUploader label='Arrastra imagenes aqui' multiple={true} handleChange={handleChange} name="file" types={fileTypes} />

      {Object.values(files).map((file, index) => (
        <img key={index} src={URL.createObjectURL(file)} width={400} />
      ))}
       */}
    </Card>
  );
}

export default Recomendations;
