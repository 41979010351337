import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Typography,
    Card,
    CardContent,
    Alert,
    Chip
} from '@mui/material';

import {DeleteOutline} from '@mui/icons-material'

import { supabase } from '../../../supabaseClient';
import DashboardAppBar from '../../../components/admin/AppBar';
import DashboardContainer from '../../../components/admin/DashboardContainer';

function Dashboard() {
    const [teams, setTeams] = useState([]);

    async function fetchTeams() {
        const { data, error } = await supabase
            .from('lencioni_teams')
            .select('*');
        if (error) {
            console.error(error);
        } else {
            setTeams(data);
        }
    }

    const getRecordCount = async (id) => {
        try {
            const { data, error } = await supabase
                .from('lencioni_records')
                .select('count(*)')
                .eq('team', id);
            if (error) {
                console.error(error);
                return 0;
            } else {
                return data[0].count;
            }
        } catch (error) {
            console.error(error);
            return 0;
        }
    };

    const handleDeleteTeam = async (teamId) => {
        const confirmed = window.confirm('¿Estás seguro de que quieres eliminar esta empresa?');
        if (confirmed) {
            try {
                const { data, error } = await supabase
                    .from('lencioni_teams')
                    .delete()
                    .eq('id', teamId);
                if (error) {
                    console.error(error);
                } else {


                        // Fetch records for each team
                        for (const team of data) {
                            console.log(team)
                            const recordCount = await getRecordCount(team.id);
                            team.records = recordCount;
                        }


                    console.log(data);
                    // Refresh the teams list after deleting
                    fetchTeams();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };



    useEffect(() => {
        fetchTeams();
    }, []);

    return (
        <>
       
            <DashboardAppBar title={'Lencioni'}/>

            <DashboardContainer>

                <TableContainer component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Miembros</TableCell>
                                <TableCell>Borrar</TableCell>
                                <TableCell>Enlace</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teams.map((team, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <a href={`/admin/lencioni/report?team=${team.id}`}>{team.name}</a>
                                    </TableCell>
                                    <TableCell> {team.records} / {team.max_users}</TableCell>
                                    <TableCell>
                                        <IconButton  onClick={() => handleDeleteTeam(team.id)}>
                                            <DeleteOutline />
                                        </IconButton>

                                    </TableCell>
                                    <TableCell>
                                        <Chip color='primary' label={`${window.location.origin}/lencioni?team=${team.id}`}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br></br>
                <Button variant="contained" color="primary" href="/admin/lencioni/create">+ Registrar empresa</Button>

                <Card>
                    <CardContent>
                        <Typography variant="h5">Formulario de invitación</Typography>
                        
                        <Alert severity='info'><a target='__blank' href='/lencioni/share'>Enlace al formulario</a></Alert>
                        <br></br>
                        
                        <img src='https://i.imgur.com/qVWlA5u.png'></img>
                    </CardContent>
                </Card>
            </DashboardContainer>
        </>
    );
}

export default Dashboard;

