import React from 'react';
import { Skeleton, Grid, Card, CardContent } from '@mui/material';

const SkeletonTeachingPitch = () => {
    return (
        <Grid container spacing={3}>
            {Array(6).fill().map((_, index) => (
                <Grid sx={{ mt: 3 }} container key={index}>
                    <Grid item xs={5}>
                        <Card>
                            <CardContent>
                                <Skeleton variant="rectangular" width={'100%'} height={160} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={7}>
                        <Card>
                            <CardContent>
                                {Array(4).fill().map((_, index) => (
                                    <Skeleton height={40} />
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default SkeletonTeachingPitch;

