import React from 'react';
import Confetti from 'react-confetti';
import { Alert, AlertTitle, Typography } from '@mui/material';

function Congratulations({brand}) {
    const style = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: brand.primary ? brand.primary : '#1769aa'
    };

    const message = {
        color: 'black',
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
    };
    
    const body = {
        paddingTop: '10px'
    }

    return (
        <div style={style}>
            <Confetti />
            <div style={message}>
                <Typography variant="h4">¡Gracias por responder! 🎉</Typography>
                <Typography variant="h6" style={body}>Tu opinión nos sera de utilidad para seguir creciendo y mejorando como equipo.</Typography>

            </div>
        </div>
    );
}
export default Congratulations;
