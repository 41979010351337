import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Card,
    CardContent,
} from '@mui/material';
import { supabase } from '../../../supabaseClient';
import { useNavigate } from 'react-router-dom';

import DashboardAppBar from '../../../components/admin/AppBar';

const CreateTeam = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [maxUsers, setMaxUsers] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleMaxUsersChange = (event) => {
        setMaxUsers(event.target.value);
    };

    const handleCreateTeam = async () => {
        try {
            const { data, error } = await supabase
                .from('lencioni_teams')
                .insert([{ name, max_users: maxUsers }]);
            if (error) {
                console.error(error);
            } else {
                console.log(data);
                // Redirect to /admin after creating team
                navigate('/admin/lencioni');
            }
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <Container>
            <DashboardAppBar title={'Crear'}/>
            <br></br><br></br><br></br><br></br><br></br>
            <Card>
                <CardContent>
                    <TextField
                        label="Nombre"
                        value={name}
                        onChange={handleNameChange}
                        fullWidth
                        sx={{ marginBottom: '10px' }}
                    />
                    <TextField
                        label="Cantidad de miembros"
                        value={maxUsers}
                        onChange={handleMaxUsersChange}
                        fullWidth
                        sx={{ marginBottom: '10px' }}
                    />
                    <Button variant="contained" color="primary" onClick={handleCreateTeam}>
                        Create
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default CreateTeam;

