import React from 'react';
import { Grid } from '@mui/material';

import Sidebar from './sidebar';
import Canvas from './canvas';

import DashboardAppBar from '../../../components/admin/AppBar';
import DashboardContainer from '../../../components/admin/DashboardContainer';

function Slides() {
    const [canvasElements, setCanvasElements] = React.useState([
    ]);
  
    function onAddItem(item) {
        const newId = canvasElements.length + 1;

        const element = {
            id: newId,
            prompt: '',
            ...item,
        }

        console.log('item',element)

        setCanvasElements((prevElements) => [...prevElements, element]);
    }
  

    return (
      <>
        <DashboardAppBar title={'Presentaciones'} />
        <DashboardContainer>
          <Grid container>
            <Grid item xs={3}>
              <Sidebar onAddItem={onAddItem} />
            </Grid>
  
            <Grid item xs={9}>
              <Canvas canvasElements={canvasElements} setCanvasElements={setCanvasElements} />
            </Grid>
          </Grid>
        </DashboardContainer>
      </>
    );
  }

export default Slides;
