import React, { useState } from 'react';
import { Box, Card, Typography, Button,Skeleton, CardContent } from '@mui/material';

import axios from 'axios';
import ReactMarkdown from 'react-markdown'

export default function ImageAnalisis({result, setResult, images, setImages}) {
    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [files, setFiles] = useState(null);
    const handleImageChange = (e) => {
        const files = e.target.files || [];

        if (files.length === 0) {
            setImages(null);
            setShowResult(false);
            return;
        }

        setFiles(files);
        console.log(files)
        setImages(Array.from(files));
        setShowResult(false);
    };


    async function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            resolve(reader.result)
          }
          reader.onerror = reject
        })
      }
      

    const handleAnalyze = async () => {
        setLoading(true);

        var imagesBase64 = [];

        for (var i = 0; i < images.length; i++) {
            const imageBase64 = await getBase64(images[i]);
            imagesBase64.push(imageBase64);
        }


        console.log(imagesBase64)

        axios.post(`${process.env.REACT_APP_BASE_URL}/activities/images`, {
            images: imagesBase64
        })
            .then((response) => {
                console.log(response.data);
                setShowResult(true);
                setLoading(false);
                setResult(response.data);
            })
            .catch((error) => {
                console.error("Error submitting the image:", error);
                setLoading(false);
            });



        // setTimeout(() => {
        //     setLoading(false);
        //     setShowResult(true);
        // }, 8000);
    };

    return (
        <div >
            <Card sx={{ mt: 5, minWidth: 275 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Análisis de Imágenes
                    </Typography>
                    <input type="file" onChange={(e) => handleImageChange(e)} accept="image/*" multiple />
                    <br />
                    {images?.length > 0 && images.map((file, index) => (
                        <img key={file.name} src={URL.createObjectURL(file)} alt="Previsualización" style={{ maxWidth: '100%', height: '150px', marginTop: '10px' }} />
                    ))}

                    <br />
                    <Button variant="contained" onClick={handleAnalyze} style={{ marginTop: '10px' }}>
                        Cargar imágenes
                    </Button>
                </CardContent>
            </Card>



            {loading ? (
                <Box>
                    <Skeleton variant="rounded" width={'100%'} height={100} sx={{ mt: 2 }} />
                    <Skeleton variant="rounded" width={'100%'} height={100} sx={{ mt: 2 }} />
                    <Skeleton variant="rounded" width={'100%'} height={100} sx={{ mt: 2 }} />
                    <Skeleton variant="rounded" width={'100%'} height={100} sx={{ mt: 2 }} />
                    <Skeleton variant="rounded" width={'100%'} height={100} sx={{ mt: 2 }} />

                    <Skeleton variant="rounded" width={'100%'} />
                </Box>
            ) : (
                <>
                
                    <ReactMarkdown>
                        {result}
                    </ReactMarkdown>

                    
                </>
            )}
        </div>
    );
}


