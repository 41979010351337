import React from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Bar from './Bar';

const styles = {
    gridItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px',
    }
};

const Item = ({ question, score, index }) => {
    return (
        <>

        <Typography variant="h6"><b>{index}.</b> {question}</Typography>
        <Bar score={score} isLeft={true} height={12} fontSize='h6' style={{paddingLeft: '40px'}} />
        <hr></hr>
        </>
    );
};

export default Item;
