import { Grid, Typography, TextField, Button } from '@mui/material';

function SearchInput({ search, setSearch, handleSubmit }) {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 5 }}>
                <Typography variant="h5">Redacta aqui tu Solución de software</Typography>
                <TextField
                    value={search}
                    multiline
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <Button variant="contained" sx={{ background: '#070e27' }} color="primary" onClick={handleSubmit}>Analizar</Button>
                        )
                    }}
                    style={{
                        borderRadius: '10px',
                        padding: '10px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                />
            </Grid>
        </Grid>

    );
}

export default SearchInput