import React, {useState,useEffect} from 'react';
import { Container, Typography, Alert } from '@mui/material';
import { ReactIcon } from 'react-icons';
import ReactLoading from 'react-loading';
import questions from '../../components/lencioni/data/questions';

import Question from '../../components/lencioni/quiz/Question';
import Toolbar from '../../components/lencioni/quiz/Toolbar';
import Congratulations from '../../components/lencioni/quiz/Congratulations';

import { supabase } from '../../supabaseClient';

function Quiz() {
    const [selectedQuestion, setSelectedQuestion] = React.useState(0);
    const [question, setQuestion] = React.useState(questions[selectedQuestion]);
    const [answers, setAnswers] = React.useState([]);
    const [showCongratulations, setShowCongratulations] = React.useState(false);
    const [team, setTeam] = React.useState('');
    const [teamData, setTeamData] = React.useState(null);
    const [brand, setBrand] = useState(null);
    
    React.useEffect(() => {
        document.title = 'Lencioni';
    })

    React.useEffect(() => {
        setQuestion(questions[selectedQuestion]);

        const queryString = window.location.search;    
        const urlParams = new URLSearchParams(queryString);

        setTeam(urlParams.get('team'))
        fetchTeamById(urlParams.get('team'))

    }, [selectedQuestion]);

    React.useEffect(() => {
        console.log('answers',answers)
        if (answers.length === questions.length) {
            setShowCongratulations(true);
            saveQuizResults(answers);
        }
    }, [answers]);
    
    async function saveQuizResults() {
        const { error } = await supabase
            .from('lencioni_records')
            .insert([{ scores: answers, team: team }])
        console.log(error)
        
    }

    async function fetchTeamById(id) {
        const { data, error } = await supabase
            .from('lencioni_teams')
            .select('*')
            .eq('id', id)
        
        if (error) {
            console.error('Error fetching team:', error);
            return;
        }
        console.log(data)
        if (data && data.length > 0) {
            const teamData = data[0];
            setTeamData(teamData);
            setBrand({
                logo: teamData.logo_url,
                primary: teamData.primary_color,
                secondary: teamData.secondary_color
            })
        }
    }

    if (!brand) return <ReactLoading height={'100%'} width={'100%'} />


    return (
        <Container>
            {showCongratulations ? (
                <Congratulations brand={brand}/>
            ) : (
                <>
                    <Question
                        question={question}
                        answers={answers}
                        setAnswers={setAnswers}
                        selectedQuestion={selectedQuestion}
                        brand={brand}
                    />
                    <Toolbar
                        selectedQuestion={selectedQuestion}
                        setSelectedQuestion={setSelectedQuestion}
                        answers={answers}
                        totalQuestions={questions.length - 1}
                        brand={brand}
                    />
                </>
            )}

            
            {brand?.logo && <div style={{marginTop: '50px', width: '100%', backgroundColor: 'white', textAlign: 'right'}}>
                <img
                    src={brand?.logo}
                    style={{ width: '120px', 'z-index': -10000 }}
                />
                
            </div>}
        </Container>
    );
}

export default Quiz;
