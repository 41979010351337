import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, Card, CardContent } from '@mui/material';

  function lighten(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}


function Option({ option, selected, setSelected, index, brand, background }) {
    const handleClick = () => {
        setSelected(index);
    };

    const brightPrimary = brand?.primary ? lighten(brand.primary, 80) : '#3f51b5';


    const listItemStyle = {
        backgroundColor: selected === index ? brightPrimary : background,
        color: selected === index ? "white" : "inherit",
        cursor: "pointer",
        borderRadius: '5px',
        marginBottom: '5px'
    };


    return (
        <ListItem
            sx={listItemStyle}
            onClick={handleClick}
        >
            <Typography sx={{'font-size': '1.1rem', 'line-height': '2.1'}}>{option}</Typography>
        </ListItem>
    );
}

function Statement({selectedQuestion, text, brand}) {
    return (
        <Card style={{ color: 'white', background: brand?.primary ? brand?.primary : '#3f51b5' }}>
            <CardContent>
                <Typography sx={{'font-size': '1.2rem', 'font-weight': 'bold'}} variant="h5">{selectedQuestion + 1}. {text}</Typography>
            </CardContent>
        </Card>
    );
}

function Question({ question, selectedQuestion, answers, setAnswers, brand }) {
    const [selected, setSelected] = React.useState(false);

    const options = ['1. Nunca', '2. Raramente', '3. Algunas veces', '4. Usualmente', '5. Siempre'];
    const optionsColors = ['#c1c1c1', '#d5d5d5', '#dddddd', '#ebebeb', '#efefef'];

    const handleClick = (index) => {
        setSelected(index);
        setAnswers(prevAnswers => {
            const newAnswers = [...prevAnswers];
            newAnswers[selectedQuestion] = index + 1;
            return newAnswers;
        });
    };

    useEffect(()=> {
        setSelected(answers[selectedQuestion] - 1)
    },[selectedQuestion])

    return (<>
        <Statement text={question.text} selectedQuestion={selectedQuestion} brand={brand}/>

        <List>
            {options.map((option, index) => (
                <Option brand={brand} background={optionsColors[index]} key={option} option={option} index={index} selected={selected} setSelected={handleClick} />
            ))}
        </List>
        </>
    );
}
export default Question;
