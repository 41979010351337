import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const determineColor = (score) => {
    if (score >= 0 && score <= 3.24) {
        return "rgb(200, 35, 38)";
    } else if (score >= 3.25 && score <= 3.74) {
        return "rgb(229,164,0)";
    } else {
        return "rgb(37,143,52)";
    }
};

const ProgressBar = ({ score, isLeft = true, height = 20, fontSize = "h5", style }) => {
    const color = determineColor(score);
    const percentage = (score / 5) * 100;

    return (
        <div style={{ height: "60px", ...style }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                {isLeft && <Typography variant={fontSize}>{Math.round(score * 100) / 100}</Typography>}
                <Box
                    style={{
                        background: color,
                        height: `${height}px`,
                        width: `${percentage}%`,
                        marginLeft: "10px",
                        marginRight: "10px"
                    }}
                />
                {!isLeft && <Typography variant={fontSize}>{Math.round(score * 100) / 100}</Typography>}
            </Box>
        </div>
    );
};

export default ProgressBar;
