import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Modal,
    Box,
    IconButton,
    Card,
    CardContent,
    Skeleton
} from '@mui/material';
import { GrMicrophone } from 'react-icons/gr';
import { HiMicrophone } from 'react-icons/hi';

import axios from 'axios';

function ActivityInput({ name, description, result, setResult, company }) {
    const [transcript, setTranscript] = useState('');
    const [listening, setListening] = useState(false);
    const [activity, setActivity] = useState('');
    const [micIcon, setMicIcon] = useState(<HiMicrophone />);
    const [loading, setLoading] = useState(false);

    const handleMicClick = () => {
        if (listening) {
            setMicIcon(<HiMicrophone />);
            recognition.stop();
        } else {
            setMicIcon(<GrMicrophone />);
            recognition.start();
        }
        setListening(!listening);
    };


    const handleSubmit = () => {
        setLoading(true)

        axios.post(`${process.env.REACT_APP_BASE_URL}/activities/generate`, {
            activity: activity,
            description: description,
        })
            .then((response) => {
                console.log(response.data);
                setResult(response.data);
                setLoading(false)
            })
            .catch((error) => {
                // Manejo del error
            });
    };
    useEffect(() => {
        if (transcript) {
            setActivity(prevActivity => prevActivity +' ' + transcript);
        }
    }, [transcript]);

    
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    const recognition = new SpeechRecognition();
    recognition.lang = 'es-CL';

    recognition.onresult = (event) => {
        const color = event.results[0][0].transcript;
        setTranscript(color);
    };

    return (
        <Card >
            <CardContent sx={{ paddingRight: '0px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} sx={{ background: company.color_primary, margin: '0px!important' }}>
                        <Typography variant="h5" sx={{ p: 3, color: 'white' }}>{name}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Grid item xs={12} lg={result ? 12 : 12}>
                            <TextField
                                value={activity}
                                placeholder="Escribe como te fue en la actividad o usa el microfono de abajo."
                                multiline
                                onChange={(e) => setActivity(e.target.value)}
                                variant="outlined"
                                fullWidth
                                rows={10}
                                style={{
                                    borderRadius: '10px',
                                    padding: '10px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>

                            {loading && <Box>
                                <Skeleton width='100%' height='40px' animation={'wave'}/>
                                <Skeleton width='100%' height='40px' animation={'wave'}/>
                                <Skeleton width='100%' height='40px' animation={'wave'}/>
                                <Skeleton width='100%' height='40px' animation={'wave'}/>
                                <Skeleton width='100%' height='40px' animation={'wave'}/>
                                <Skeleton width='100%' height='40px' animation={'wave'}/>
                                
                            </Box>}

                            {result && <TextField
                                value={result}
                                placeholder=""
                                multiline
                                onChange={(e) => setResult(e.target.value)}
                                variant="outlined"
                                fullWidth
                                rows={15}
                                style={{
                                    borderRadius: '10px',
                                    padding: '10px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                }}
                            />}
                        </Grid>
                        <IconButton
                            onClick={handleMicClick}
                            sx={{ fontSize: '50px', color: company.color_primary, mt: 2 }}
                        >
                            {micIcon}
                        </IconButton>


                        <span style={{ fontSize: '18px', fontWeight: 'bold', color: company.color_primary }}>{listening ? 'Escuchando...' : ''}</span>
                        <Button onClick={handleSubmit} variant="contained" sx={{ background: company.color_primary, float: 'right', mt: 2 }}>
                            Generar informe
                        </Button>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
}

export default ActivityInput;
