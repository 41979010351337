// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tip-title{
    font-family: 'Noto Serif', serif!important;
    letter-spacing: -0.3px;
    font-size: 20px!important;
    display: flex;
    align-items: flex-start;
}

.tip-content{
    padding-top: 32px!important;
}

.title{
    font-family: 'Noto Serif', serif!important;
    font-size: 25px!important;
    line-height: 31px;
    letter-spacing: -0.3px;
}

.card-body:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: -100%;
    left: 0;
    background: linear-gradient(0deg,#070f26,transparent);
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/CaseStudies.css"],"names":[],"mappings":"AAEA;IACI,0CAA0C;IAC1C,sBAAsB;IACtB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0CAA0C;IAC1C,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,qDAAqD;IACrD,UAAU;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');\n\n.tip-title{\n    font-family: 'Noto Serif', serif!important;\n    letter-spacing: -0.3px;\n    font-size: 20px!important;\n    display: flex;\n    align-items: flex-start;\n}\n\n.tip-content{\n    padding-top: 32px!important;\n}\n\n.title{\n    font-family: 'Noto Serif', serif!important;\n    font-size: 25px!important;\n    line-height: 31px;\n    letter-spacing: -0.3px;\n}\n\n.card-body:after {\n    content: \"\";\n    width: 100%;\n    height: 100%;\n    display: block;\n    position: absolute;\n    top: -100%;\n    left: 0;\n    background: linear-gradient(0deg,#070f26,transparent);\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
