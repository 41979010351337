
import { FaHandshake } from 'react-icons/fa';
import { SlFrame } from 'react-icons/sl'
import { BiBrain } from 'react-icons/bi'
import { MdSentimentSatisfiedAlt } from "react-icons/md";
import { BsFillLightbulbFill } from "react-icons/bs";
import { TbArrowsDoubleNeSw } from "react-icons/tb";


const tips = [
    {
      title: "Warmer",
      icon: <FaHandshake size={35} />,
      description: "En esta fase, el representante de ventas establece una conexión con el comprador al identificar y comprender sus necesidades y problemas.El objetivo es crear una relación de confianza.",
    },
    {
      title: "Reframe",
      icon: <SlFrame size={35} />,
      description: "En esta etapa, el representante desafía al comprador a ver su problema desde una perspectiva diferente o sorprendente.Esto implica ofrecer una visión novedosa que haga que el comprador reconsidere su enfoque actual.",
    },
    {
      title: "Rational Drowning",
      icon: <BiBrain size={35} />,
      description: "Aquí, el representante presenta datos y cifras que resaltan los costos y las implicaciones del problema que el comprador está enfrentando.El objetivo es crear una preocupación lógica y financiera.",
    },
    {
      title: "Emotional Impact",
      icon: <MdSentimentSatisfiedAlt size={35} />,
      description: "En esta fase, el representante comparte historias y ejemplos de cómo otras empresas han experimentado problemas similares y han sufrido las consecuencias.Se busca generar una respuesta emocional y empatía en el comprador.",
    },
    {
      title: "New Way",
      description: "El representante sugiere un cambio en el comportamiento del comprador para abordar y resolver el problema de manera efectiva.Esto implica presentar una nueva forma de hacer las cosas.",
      icon: <TbArrowsDoubleNeSw size={35} />,
    },
    {
      title: "Your Solution",
      icon: <BsFillLightbulbFill size={35} />,
      description: "Finalmente, el representante presenta la solución de su empresa como la mejor manera de implementar el cambio de comportamiento sugerido y resolver el problema de manera eficiente y efectiva.Se enfatiza por qué la solución de su empresa es superior a otras alternativas.",
    }
  ];

  export default tips;
