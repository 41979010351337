import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Card,
    CardContent,
    Grid
} from '@mui/material';
import { supabase } from '../../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { MuiColorInput } from 'mui-color-input'

import DashboardAppBar from '../../../components/admin/AppBar';

const CreateTeam = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [colorBackground, setColorBackground] = useState('#000000'); // Inicializa el color con un valor predeterminado
    const [colorPrimary, setColorPrimary] = useState('#000000'); // Inicializa el color con un valor predeterminado
    const [logoUrl, setLogoUrl] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleLogoUrlChange = (event) => {
        setLogoUrl(event.target.value);
    };

    const handleCreateCompany = async () => {
        try {
            const { data, error } = await supabase
                .from('companies')
                .insert([{ name, color_background : colorBackground, color_primary: colorPrimary, logo_url: logoUrl }]);
            if (error) {
                console.error(error);
            } else {
                console.log(data);
                navigate('/admin/companies');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container>
            <DashboardAppBar title={'Crear Empresa'} />
            <br></br><br></br><br></br><br></br><br></br>
            <Card>
                <CardContent>
                    <TextField
                        label="Nombre"
                        value={name}
                        onChange={handleNameChange}
                        fullWidth
                        sx={{ marginBottom: '10px' }}
                    />
                    <Grid container>
                        <Grid item sx={6}>
                            <MuiColorInput value={colorBackground} onChange={(value) => setColorBackground(value)} />
                        </Grid>

                        <Grid item sx={6}>
                            <MuiColorInput value={colorPrimary} onChange={(value) => setColorPrimary(value)} />
                        </Grid>

                        <Grid item sx={6}>
                            <TextField
                                label="URL del Logo"
                                value={logoUrl}
                                onChange={handleLogoUrlChange}
                                fullWidth
                                sx={{ marginBottom: '10px' }}
                            />
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="primary" onClick={handleCreateCompany}>
                        Registrar cliente
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default CreateTeam;
