import { Typography, Grid, Card, CardContent } from '@mui/material';

function Tip({ tip }) {
    return (
        <>
            <Card sx={{mb: 2}}>
                
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5} sx={{ backgroundColor: '#070f26', color: 'white' }}>
                        <CardContent className='tip-content'>
                            <Typography className='tip-title' variant='h5'>
                                {tip.icon}  <span style={{marginLeft: '15px'}}>{tip.title}</span> {/* <FaHandshake />  size={35} style={{ marginRight: '10px' }}  */}
                            </Typography>
                            <Typography sx={{mt: 2}} variant='body2' color={'#9f9f9f'}> {tip.description}</Typography>

                        </CardContent>
                    </Grid>
                    <Grid item xs={12} sm={7}  style={{display: 'flex', alignItems: 'center'}}>
                        <CardContent>
                            <Typography sx={{fontSize: '18px'}}>{tip.content}</Typography>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
}

export default Tip