import './CaseStudies.css'
import axios from 'axios'
import React, {useEffect} from 'react';
import { Grid, AppBar, Toolbar, Container, Typography,Button} from '@mui/material';
import { HiPresentationChartBar } from 'react-icons/hi'
import TeachingPitch from '../components/CaseStudies/TeachingPitch'
import SearchResult from '../components/CaseStudies/SearchResult'
import SearchInput from '../components/CaseStudies/SearchInput'
import SkeletonTeachingPitch from '../components/CaseStudies/SkeletonTeachingPitch'

import tipsData from '../components/CaseStudies/Tips'

import Navbar from '../components/common/Navbar'

function CaseStudies() {

  const [search, setSearch] = React.useState('');
  const [searchResult, setSearchResult] = React.useState(null);

  const [loadingTeachingPitch, setLoadingTeachingPitch] = React.useState(false)
  const [loadedTeachingPitch, setLoadedTeachingPitch] = React.useState(false)

  const [tips, setTips] = React.useState(null)

  const handleGetTeachingPitch = async (event) => {

    setLoadingTeachingPitch(true)

    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/casestudies/teachingpitch', { solution: search  });
      const content = JSON.parse(response.data);

      const newTips = tipsData.map(tip => ({
        ...tip,
        content: content[tip.title]
      }));

      setTips(newTips)

      setLoadingTeachingPitch(false)
      setLoadedTeachingPitch(true)
    } catch (error) {
      console.error(error);
    }

  };


  const handleSearch = async () => {
    const userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/casestudies/solution', { message: search, userId});
      console.log(response.data.response);
      setSearchResult(JSON.parse(response.data.response));
    } catch (error) {
      console.error(error);
    }

  }

  const handleSubmit = async (event) => {
    await handleGetTeachingPitch()
    await handleSearch()
    
  };

  useEffect(() => {
    window.addEventListener('error', e => {
        if (e.message === 'ResizeObserver loop limit exceeded') {
            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
        }
    });
  }, []);

  function handleDownload() {
    const queryParams = tips.map(tip => `texts=${encodeURIComponent(tip.content)}`).join('&');
    const url = `${process.env.REACT_APP_BASE_URL}/casestudies/ppt?${queryParams}`;

    try {
        window.open(url, '_blank');
    } catch (error) {
        console.error(error);
    }
}


  
  return (
    <>
      <Navbar/>

      <Container>

        <SearchInput search={search} setSearch={setSearch} handleSubmit={handleSubmit} />

        {loadingTeachingPitch ? <SkeletonTeachingPitch/> :  loadedTeachingPitch && <TeachingPitch tips={tips} />}

        {searchResult && <>
          <Typography variant="h6" component="p"> Casos de exito similares a tu propuesta</Typography>
          <Grid container>
            <SearchResult searchResult={searchResult} />

          </Grid>

          <Button 
                variant="contained" 
                sx={{mt:5, width: '100%', marginBottom:'10px', background: '#070f26', padding: '20px', borderRadius: '35px', '&:hover': { background: '#070f26' }}}
                startIcon={<HiPresentationChartBar size={35}/>} 
                onClick={handleDownload}
            >
                    Descargar Presentación
            </Button>

         
          </>}
      </Container>
    </>
  );
}

export default CaseStudies;
