import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const useCompanyData = () => {
  const [companyData, setCompanyData] = useState(null);
  const [error, setError] = useState(null);

  const location = useLocation();
  const companyId = new URLSearchParams(location.search).get('company') || '2';

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {

        const { data, error } = await supabase
          .from('companies')
          .select('*')
          .eq('id', companyId)
          .single();

        if (error) {
          throw error;
        }

        setCompanyData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    if (companyId) {
      fetchCompanyData();
    }

  }, [companyId]);

  return companyData;
};

export default useCompanyData;
