import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LinearProgress } from '@mui/material';

function Toolbar({ selectedQuestion, totalQuestions, setSelectedQuestion, answers, brand }) {
    const [isDisabledBack, setIsDisabledBack] = useState(false);
    const [isDisabledForward, setIsDisabledForward] = useState(false);

    const handleBack = () => {
        if (!isDisabledBack) {
            setSelectedQuestion(selectedQuestion - 1);
        }
    };

    const handleForward = () => {
        if (!isDisabledForward) {
            setSelectedQuestion(selectedQuestion + 1);
        }
    };

    useEffect(() => {
        setIsDisabledBack(
            selectedQuestion === 0
        );
        setIsDisabledForward(
            answers[selectedQuestion] === undefined
        );
    }, [selectedQuestion, answers]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
                style={{ backgroundColor: brand?.secondary ? brand?.secondary : brand?.primary, color: 'white' }}
                variant="contained" startIcon={<ArrowBackIcon />}
                onClick={handleBack}
                disabled={isDisabledBack} />
            <Typography variant="h6" style={{ margin: '0 10px' }}>
                {selectedQuestion + 1}/{totalQuestions}
            </Typography>

            <LinearProgress
                sx={{'& .MuiLinearProgress-bar': {
                        backgroundColor: brand?.primary
                      }}}
                variant="determinate" value={(selectedQuestion / totalQuestions) * 100}
                style={{ flexGrow: 1, marginRight: '10px' }} />
            <Button
                style={{ backgroundColor: brand?.secondary ? brand?.secondary : brand?.primary, color: 'white' }}
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={handleForward}
                disabled={isDisabledForward} />
        </div>
    );
}

export default Toolbar;