const recomendations = {
    trust: {
      red: {
        leader_selfknowledge: [
          {
            todo: "Identifica tus valores personales y cómo se relacionan con tus decisiones de liderazgo.",
            howto: "Reflexiona sobre lo que es más importante para ti en la vida y cómo esos valores se alinean con tus acciones y decisiones como líder."
          },
          {
            todo: "Practica la autenticidad y la transparencia en tu liderazgo.",
            howto: "Muestra tu verdadero yo a tu equipo, comparte tus pensamientos y emociones de manera honesta y evita ocultar información relevante."
          },
          {
            todo: "Busca oportunidades de desarrollo personal y profesional de manera proactiva.",
            howto: "Investiga cursos, conferencias o talleres que puedan ayudarte a mejorar tus habilidades de liderazgo y autoconocimiento."
          },
          {
            todo: "Identifica tus patrones de comportamiento bajo estrés o presión.",
            howto: "Observa cómo reaccionas en situaciones estresantes y busca formas de gestionar esas respuestas de manera más efectiva."
          },
          {
            todo: "Practica la autorreflexión regularmente.",
            howto: "Dedica tiempo periódicamente para meditar, llevar un diario o simplemente pensar en tus experiencias y lecciones aprendidas."
          },
          {
            todo: "Busca un mentor o coach para recibir orientación externa.",
            howto: "Encuentra a alguien con experiencia en liderazgo que pueda proporcionarte consejos y guía personalizados."
          },
          {
            todo: "Mantén una mentalidad de crecimiento y adaptación continua.",
            howto: "Abraza el cambio y la evolución personal como parte integral de tu desarrollo como líder."
          },
          {
            todo: "Evalúa cómo tu liderazgo impacta en el bienestar y la satisfacción de tus colaboradores.",
            howto: "Pregunta a tus colaboradores sobre su experiencia trabajando contigo y busca formas de mejorar su ambiente laboral."
          },
          {
            todo: "Identifica tus sesgos personales y trabaja en mitigarlos.",
            howto: "Reflexiona sobre prejuicios o suposiciones que puedas tener y busca contrarrestarlos con decisiones basadas en datos y evidencia."
          },
          {
            todo: "Fomenta la autorreflexión en tu equipo.",
            howto: "Anima a tus colaboradores a reflexionar sobre su propio desarrollo y crecimiento profesional, promoviendo un sentido de responsabilidad personal."
          }
        ],
        leader_relation: [
          {
            todo: "Fomenta la toma de decisiones colaborativas en el equipo.",
            howto: "Invita a los miembros del equipo a participar en la toma de decisiones y valora sus aportes."
          },
          {
            todo: "Proporciona oportunidades para el desarrollo de habilidades blandas, como la comunicación y la resolución de conflictos.",
            howto: "Ofrece capacitación y ejercicios específicos para mejorar estas habilidades en el equipo."
          },
          {
            todo: "Establece un ambiente de trabajo basado en la confianza mutua.",
            howto: "Crea normas de equipo que fomenten la confianza y asegúrate de que se sigan."
          },
          {
            todo: "Fomenta la colaboración interdepartamental.",
            howto: "Promueve la comunicación y la cooperación entre diferentes áreas de la organización para lograr objetivos comunes."
          },
          {
            todo: "Fomenta la diversidad de pensamiento en el equipo.",
            howto: "Anima a los miembros del equipo a aportar diferentes perspectivas y enfoques a los desafíos y problemas."
          },
          {
            todo: "Implementa prácticas de reconocimiento y recompensa equitativas.",
            howto: "Asegúrate de que las recompensas y el reconocimiento se distribuyan de manera justa y en función del desempeño."
          },
          {
            todo: "Promueve la comunicación abierta entre departamentos.",
            howto: "Facilita reuniones interdepartamentales y alienta la colaboración entre equipos para abordar problemas comunes."
          },
          {
            todo: "Establece un sistema de retroalimentación de 360 grados.",
            howto: "Implementa un proceso en el que los miembros del equipo puedan proporcionarse retroalimentación mutua, incluyendo superiores, pares y subordinados."
          },
          {
            todo: "Organiza eventos y actividades de team building.",
            howto: "Planifica actividades que ayuden a fortalecer los lazos dentro del equipo, como retiros, eventos sociales o ejercicios de construcción de confianza."
          },
          {
            todo: "Facilita la resolución de conflictos de manera efectiva.",
            howto: "Proporciona herramientas y procesos para abordar los conflictos constructivamente y busca soluciones colaborativas."
          }
        ],
        team_actions: [
          {
            todo: "Apoya el desarrollo de habilidades específicas para cada colaborador.",
            howto: "Identifica las habilidades clave que cada colaborador necesita y ofrece recursos para su crecimiento."
          },
          {
            todo: "Ayuda a cada colaborador a establecer metas SMART (específicas, medibles, alcanzables, relevantes y con límite de tiempo).",
            howto: "Colabora con cada empleado para definir objetivos claros y alcanzables."
          },
          {
            todo: "Sé flexible y adapta tu estilo de liderazgo a las necesidades individuales.",
            howto: "Reconoce que cada colaborador es único y ajusta tu enfoque para respaldar su éxito."
          },
          {
            todo: "Comparte el propósito y la visión del equipo de manera clara y emocionante.",
            howto: "Inspira a cada colaborador al mostrar cómo su trabajo contribuye a los objetivos globales del equipo y la organización."
          },
          {
            todo: "Fomenta la retroalimentación regular y abierta entre tú y cada colaborador.",
            howto: "Crea un ambiente en el que las opiniones sean bienvenidas y las conversaciones sean honestas y constructivas."
          },
          {
            todo: "Proporciona oportunidades para el desarrollo de liderazgo.",
            howto: "Identifica a los colaboradores con potencial de liderazgo y ofréceles proyectos o responsabilidades que fomenten su crecimiento en ese aspecto."
          },
          {
            todo: "Apoya el equilibrio entre el trabajo y la vida personal.",
            howto: "Comprende las necesidades individuales de tus colaboradores en cuanto a horarios y responsabilidades familiares y adapta las políticas según corresponda."
          },
          {
            todo: "Fomenta la autonomía y la toma de decisiones.",
            howto: "Delega responsabilidades y autoridad a tus colaboradores, dándoles espacio para tomar decisiones y aprender de los resultados."
          },
          {
            todo: "Escucha activamente las preocupaciones y sugerencias de tus colaboradores.",
            howto: "Presta atención a sus comentarios, sin interrupciones, y demuestra que valoras sus opiniones tomando medidas adecuadas cuando sea necesario."
          },
          {
            todo: "Proporciona retroalimentación específica sobre el progreso hacia metas y objetivos personales.",
            howto: "Comunica regularmente el avance de cada colaborador hacia sus metas y brinda orientación para superar obstáculos."
          }
        ],
        message: "Querido líder, abordar la falta de confianza en tu equipo es un desafío valiente y un paso importante hacia un ambiente de trabajo más fuerte y colaborativo. Confía en tu capacidad para crecer y mejorar en esta área. Tu dedicación y compromiso son admirables. Juntos, con esfuerzo y perseverancia, puedes construir un equipo sólido y exitoso. ¡Adelante y lidera con confianza y determinación!"
      },
      yellow: {
        leader_selfknowledge: [
          {
            todo: "Realiza una autoevaluación de tus habilidades de construcción de confianza.",
            howto: "Reflexiona sobre cómo te sientes con respecto a tu capacidad para generar confianza en tu equipo y busca áreas de mejora."
          },
          {
            todo: "Investiga tus propios patrones de comportamiento en situaciones que requieren confianza.",
            howto: "Analiza cómo reaccionas cuando los miembros del equipo expresan preocupaciones o desacuerdos y busca formas de responder de manera más efectiva."
          },
          {
            todo: "Busca retroalimentación específica sobre tu capacidad para inspirar confianza en los demás.",
            howto: "Pregunta a tus colaboradores directos y colegas cómo perciben tu habilidad para generar confianza y toma nota de sus comentarios."
          },
          {
            todo: "Identifica tus propios miedos o inseguridades que podrían estar afectando tu capacidad para generar confianza.",
            howto: "Reflexiona sobre las situaciones que te hacen sentir incómodo o inseguro en tu rol de líder."
          },
          {
            todo: "Investiga cómo tus valores personales pueden estar influyendo en la forma en que construyes confianza.",
            howto: "Reflexiona sobre tus valores y cómo se relacionan con tu liderazgo, y considera si necesitas alinearlos mejor."
          },
          {
            todo: "Participa en un programa de desarrollo de habilidades de liderazgo enfocado en la construcción de confianza.",
            howto: "Busca oportunidades de formación específicas que te ayuden a mejorar esta área de tu liderazgo."
          },
          {
            todo: "Comparte tus propias experiencias personales y desafíos relacionados con la confianza con tu equipo.",
            howto: "Al compartir tus propias historias, puedes crear un ambiente donde otros se sientan más cómodos compartiendo sus propias preocupaciones."
          },
          {
            todo: "Practica la empatía y la escucha activa para comprender mejor las necesidades emocionales de tu equipo.",
            howto: "Escucha a tus colaboradores sin juzgar, muestra interés genuino y valida sus sentimientos y preocupaciones."
          },
          {
            todo: "Evalúa tus decisiones y acciones en función de cómo pueden afectar la confianza de tu equipo.",
            howto: "Antes de tomar decisiones importantes, considera cómo pueden percibirse y afectar la confianza en el equipo."
          },
          {
            todo: "Define un plan personal para mejorar tu capacidad de construir confianza y establece metas específicas a corto y largo plazo.",
            howto: "Establece objetivos concretos para mejorar la confianza en tu equipo y haz un seguimiento regular de tu progreso."
          }
        ],
        leader_relation: [
          {
            todo: "Comparte tus esfuerzos y metas personales para construir confianza con el equipo.",
            howto: "Explica a tu equipo que estás comprometido en mejorar la confianza y busca su apoyo y colaboración en este esfuerzo."
          },
          {
            todo: "Establece expectativas claras sobre el papel de cada miembro del equipo en la construcción de confianza.",
            howto: "Comunica que la confianza es una responsabilidad compartida y que todos deben contribuir."
          },
          {
            todo: "Lleva a cabo reuniones regulares para discutir y abordar la falta de confianza.",
            howto: "Dedica tiempo en las reuniones para hablar abiertamente sobre las preocupaciones de confianza y busca soluciones de equipo."
          },
          {
            todo: "Implementa un proceso de retroalimentación anónima para que los miembros del equipo puedan expresar sus preocupaciones de manera segura.",
            howto: "Crea un mecanismo confidencial para recopilar comentarios y asegúrate de que los resultados se utilicen para el mejoramiento continuo."
          },
          {
            todo: "Lidera con el ejemplo al ser transparente y abierto en tus comunicaciones y decisiones.",
            howto: "Muestra cómo la transparencia y la apertura son valores importantes en el equipo."
          },
          {
            todo: "Celebra los éxitos tempranos en la construcción de confianza.",
            howto: "Reconoce y recompensa los comportamientos y acciones que contribuyan a la mejora de la confianza en el equipo."
          },
          {
            todo: "Promueve la colaboración y el trabajo en equipo en lugar de la competencia interna.",
            howto: "Fomenta la cooperación y la comunicación efectiva entre los miembros del equipo."
          },
          {
            todo: "Comparte recursos y herramientas que pueden ayudar al equipo a construir confianza.",
            howto: "Proporciona lecturas, capacitación o ejercicios específicos que aborden la construcción de confianza."
          },
          {
            todo: "Facilita sesiones de construcción de equipo diseñadas para fortalecer la confianza.",
            howto: "Organiza actividades o talleres que se centren en la construcción de confianza y la mejora de las relaciones dentro del equipo."
          },
          {
            todo: "Alienta a los miembros del equipo a expresar sus preocupaciones directamente y de manera constructiva.",
            howto: "Establece un clima en el que se valoren las opiniones honestas y se busquen soluciones juntos."
          }
        ],
        team_actions: [
          {
            todo: "Programa reuniones individuales con cada colaborador para discutir sus preocupaciones y expectativas relacionadas con la confianza.",
            howto: "Agenda reuniones privadas para escuchar a cada colaborador y ofrecer apoyo."
          },
          {
            todo: "Proporciona retroalimentación específica sobre cómo cada colaborador puede contribuir a la construcción de confianza en el equipo.",
            howto: "Sé claro sobre las acciones y comportamientos que pueden ayudar a fortalecer la confianza en el equipo."
          },
          {
            todo: "Ofrece oportunidades para el desarrollo de habilidades de construcción de confianza de manera individualizada.",
            howto: "Identifica las necesidades específicas de cada colaborador y proporciona recursos o capacitación adecuados."
          },
          {
            todo: "Ayuda a cada colaborador a establecer metas personales relacionadas con la confianza y el trabajo en equipo.",
            howto: "Colabora con cada empleado para definir objetivos claros y medibles en estas áreas."
          },
          {
            todo: "Proporciona apoyo emocional y profesional a los colaboradores que puedan estar lidiando con desafíos personales que afecten la confianza.",
            howto: "Sé un recurso de apoyo y ofrece flexibilidad cuando sea necesario."
          },
          {
            todo: "Lleva un registro del progreso individual de cada colaborador en la construcción de confianza.",
            howto: "Mantén un seguimiento de los logros y los desafíos de cada empleado y proporciona retroalimentación regular."
          },
          {
            todo: "Proporciona oportunidades para el liderazgo y la toma de decisiones en función de las fortalezas individuales de cada colaborador.",
            howto: "Reconoce y delega responsabilidades en función de las habilidades y el potencial de cada empleado."
          },
          {
            todo: "Escucha activamente las preocupaciones y sugerencias de cada colaborador.",
            howto: "Presta atención a sus comentarios, sin interrupciones, y demuestra que valoras sus opiniones tomando medidas adecuadas cuando sea necesario."
          },
          {
            todo: "Proporciona retroalimentación específica sobre el progreso hacia metas y objetivos personales relacionados con la confianza.",
            howto: "Comunica regularmente el avance de cada colaborador hacia sus metas y brinda orientación para superar obstáculos."
          },
          {
            todo: "Establece un canal de comunicación abierto y confiable con cada colaborador, donde puedan abordar problemas de confianza en cualquier momento.",
            howto: "Asegúrate de que tus colaboradores sepan que pueden acercarse a ti en cualquier momento para discutir preocupaciones y quejas relacionadas con la confianza."
          }
        ],
        message: "Querido líder, abordar la falta de confianza en tu equipo es un desafío valiente y un paso importante hacia un ambiente de trabajo más fuerte y colaborativo. Confía en tu capacidad para crecer y mejorar en esta área. Tu dedicación y compromiso son admirables. Juntos, con esfuerzo y perseverancia, puedes construir un equipo sólido y exitoso. ¡Adelante y lidera con confianza y determinación!"
      },
      green: {
        leader_selfknowledge: [
          {
            todo: "Continúa evaluando y mejorando tu autoconocimiento como líder.",
            howto: "Realiza evaluaciones regulares de tus habilidades de liderazgo, busca retroalimentación y reflexiona sobre tu desempeño."
          },
          {
            todo: "Sigue buscando retroalimentación de colegas, superiores y colaboradores.",
            howto: "Fomenta un ambiente en el que la retroalimentación sea una práctica común y valiosa."
          },
          {
            todo: "Mantén una práctica constante de autoevaluación de tus acciones y decisiones.",
            howto: "Continúa reflexionando sobre tus decisiones pasadas y busca formas de mejorar constantemente."
          },
          {
            todo: "Comprende cómo tus valores personales influyen en tu liderazgo y sigue alineándolos con tus acciones.",
            howto: "Continúa reflexionando sobre tus valores y asegúrate de que se reflejen en tus decisiones y acciones como líder."
          },
          {
            todo: "Busca oportunidades de desarrollo personal y profesional para seguir creciendo como líder.",
            howto: "Mantente al tanto de las últimas tendencias en liderazgo y busca oportunidades de aprendizaje y desarrollo."
          },
          {
            todo: "Sigue participando en programas de desarrollo de liderazgo y coaching.",
            howto: "Continúa invirtiendo en tu desarrollo personal y busca mentores o coaches que te ayuden a alcanzar tus metas."
          },
          {
            todo: "Mantén un registro de tus reflexiones sobre tu liderazgo y tus áreas de mejora.",
            howto: "Lleva un diario de tus experiencias y pensamientos relacionados con tu liderazgo para un crecimiento continuo."
          },
          {
            todo: "Continúa buscando la mentoría de líderes más experimentados.",
            howto: "Mantén relaciones con líderes que puedan proporcionarte orientación y consejos valiosos."
          },
          {
            todo: "Sigue desarrollando un plan de desarrollo personal y profesional.",
            howto: "Actualiza y ajusta tu plan de desarrollo a medida que evolucionan tus objetivos y necesidades."
          },
          {
            todo: "Mantén estrategias efectivas para gestionar el estrés y la presión en tu rol de líder.",
            howto: "Continúa practicando técnicas de manejo del estrés, como la meditación, el ejercicio y la gestión del tiempo."
          }
        ],
        leader_relation: [
          {
            todo: "Continúa estableciendo expectativas claras de comportamiento y rendimiento en el equipo.",
            howto: "Asegúrate de que todos comprendan las expectativas y objetivos del equipo."
          },
          {
            todo: "Sigue comunicando abierta y regularmente la visión y los objetivos del equipo.",
            howto: "Mantén una comunicación consistente para mantener a todos enfocados en los objetivos compartidos."
          },
          {
            todo: "Continúa escuchando activamente a los miembros del equipo y valorando sus perspectivas.",
            howto: "Fomenta un ambiente donde los miembros del equipo se sientan escuchados y respetados."
          },
          {
            todo: "Sigue promoviendo un ambiente de trabajo inclusivo y diverso.",
            howto: "Asegúrate de que todos tengan igualdad de oportunidades y se sientan valorados sin importar su origen o identidad."
          },
          {
            todo: "Continúa celebrando los éxitos y logros del equipo de manera pública y privada.",
            howto: "Reconoce y premia los logros del equipo para mantener la moral alta."
          },
          {
            todo: "Fomenta la colaboración y el trabajo en equipo en lugar de la competencia interna.",
            howto: "Anima a los miembros del equipo a trabajar juntos para alcanzar metas comunes."
          },
          {
            todo: "Continúa brindando apoyo y recursos para el desarrollo profesional de los miembros del equipo.",
            howto: "Ofrece oportunidades de capacitación y crecimiento personalizado para cada miembro del equipo."
          },
          {
            todo: "Sigue ofreciendo oportunidades para la capacitación y el aprendizaje continuo.",
            howto: "Proporciona recursos y acceso a programas de formación relevantes."
          },
          {
            todo: "Continúa siendo accesible y abierto a recibir retroalimentación constructiva.",
            howto: "Fomenta un ambiente en el que los miembros del equipo se sientan cómodos compartiendo comentarios honestos."
          },
          {
            todo: "Sigue resolviendo los conflictos de manera justa y constructiva para mantener un ambiente de trabajo armonioso.",
            howto: "Aborda los conflictos de manera imparcial y busca soluciones que beneficien al equipo en su conjunto."
          }
        ],
        team_actions: [
          {
            todo: "Mantén reuniones individuales regulares para comprender las metas y desafíos de cada colaborador.",
            howto: "Programa reuniones uno a uno para discutir el desarrollo de cada empleado."
          },
          {
            todo: "Sigue proporcionando retroalimentación específica y constructiva sobre el desempeño de cada colaborador.",
            howto: "Ofrece retroalimentación regular que incluya ejemplos concretos y sugerencias para mejoras."
          },
          {
            todo: "Continúa fomentando el desarrollo profesional personalizado, incluyendo la identificación de oportunidades de capacitación.",
            howto: "Colabora con cada empleado para definir metas de desarrollo y proporciona recursos y apoyo según sea necesario."
          },
          {
            todo: "Sigue reconociendo y premiando los logros y contribuciones individuales de cada colaborador.",
            howto: "Celebra los éxitos individuales de manera pública y privada y ofrece recompensas apropiadas."
          },
          {
            todo: "Continúa ayudando a cada colaborador a establecer metas personales y profesionales claras.",
            howto: "Colabora en la definición de objetivos SMART y revisa regularmente el progreso."
          },
          {
            todo: "Sigue brindando oportunidades de liderazgo y desarrollo de habilidades específicas para cada colaborador.",
            howto: "Identifica las fortalezas individuales y ofrece proyectos o responsabilidades que las aprovechen."
          },
          {
            todo: "Continúa fomentando un ambiente de confianza y apertura con cada colaborador.",
            howto: "Mantén una comunicación abierta y asegúrate de que los colaboradores se sientan cómodos compartiendo preocupaciones y sugerencias contigo."
          },
          {
            todo: "Proporciona retroalimentación específica sobre el progreso hacia metas y objetivos individuales.",
            howto: "Comunica el avance de cada colaborador hacia sus metas y brinda orientación para superar obstáculos."
          },
          {
            todo: "Sigue ofreciendo oportunidades para el aprendizaje y el crecimiento personal.",
            howto: "Proporciona acceso a recursos educativos y programas de formación relevantes."
          },
          {
            todo: "Mantén un enfoque en el desarrollo a largo plazo de cada colaborador y en su crecimiento dentro del equipo y la organización.",
            howto: "Colabora con cada empleado para identificar oportunidades de crecimiento y desarrollo a largo plazo."
          }
        ]
      }
    },
    conflict: {
      red: {
        leader_selfknowledge: [
          {
            todo: "Reconoce tu miedo al conflicto y la importancia de abordarlo.",
            howto: "Reflexiona sobre cómo te sientes ante el conflicto y reconoce que es una parte natural de las relaciones humanas y del trabajo en equipo."
          },
          {
            todo: "Identifica situaciones pasadas en las que evitaste el conflicto y analiza las consecuencias.",
            howto: "Examina casos en los que el miedo al conflicto pudo haber tenido un impacto negativo en el equipo o en los resultados."
          },
          {
            todo: "Practica la autoevaluación regular para comprender tus propios desencadenantes emocionales en situaciones de conflicto.",
            howto: "Reflexiona sobre las emociones que surgen en situaciones conflictivas y cómo influyen en tu comportamiento."
          },
          {
            todo: "Busca retroalimentación honesta sobre tu manejo del conflicto de parte de colegas, superiores y colaboradores.",
            howto: "Pide a otros que compartan sus percepciones sobre tu respuesta al conflicto y toma nota de sus comentarios."
          },
          {
            todo: "Investiga tus valores personales y cómo pueden influir en tu manejo del conflicto.",
            howto: "Considera cómo tus valores pueden impactar en tu disposición para abordar el conflicto y ajusta si es necesario."
          },
          {
            todo: "Busca oportunidades de desarrollo específicas para mejorar tus habilidades en la gestión del conflicto.",
            howto: "Inscríbete en talleres, cursos o programas de entrenamiento sobre la resolución de conflictos."
          },
          {
            todo: "Practica la empatía y la escucha activa para entender las perspectivas de los demás.",
            howto: "Enfócate en comprender las preocupaciones y necesidades de los demás antes de responder."
          },
          {
            todo: "Investiga tus patrones de comunicación y cómo pueden contribuir al miedo al conflicto.",
            howto: "Examina cómo te comunicas en situaciones de conflicto y busca formas de mejorar tus habilidades comunicativas."
          },
          {
            todo: "Mantén un registro de tus respuestas en situaciones de conflicto para identificar patrones y áreas de mejora.",
            howto: "Lleva un registro de tus reacciones emocionales y acciones durante los conflictos."
          },
          {
            todo: "Establece un plan personal de desarrollo para superar el miedo al conflicto y establece metas específicas.",
            howto: "Define objetivos claros para abordar el conflicto de manera efectiva y revisa regularmente tu progreso."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica la importancia de abordar el conflicto de manera constructiva en el equipo.",
            howto: "Explica que el conflicto puede ser una fuente de crecimiento y mejora si se maneja adecuadamente."
          },
          {
            todo: "Establece expectativas claras de que el equipo debe abordar los conflictos abierta y respetuosamente.",
            howto: "Asegúrate de que todos comprendan que se espera que los conflictos se discutan y resuelvan de manera adecuada."
          },
          {
            todo: "Implementa un proceso estructurado para gestionar el conflicto en el equipo.",
            howto: "Crea un procedimiento que guíe la resolución de conflictos de manera efectiva, incluyendo pasos y responsabilidades claras."
          },
          {
            todo: "Organiza talleres o ejercicios de desarrollo de habilidades para el equipo en relación con la gestión del conflicto.",
            howto: "Ofrece oportunidades de capacitación y práctica para que el equipo mejore sus habilidades en esta área."
          },
          {
            todo: "Fomenta la confianza y la apertura en el equipo para que los miembros se sientan cómodos expresando sus preocupaciones.",
            howto: "Crea un ambiente en el que los miembros del equipo se sientan seguros al hablar sobre temas conflictivos."
          },
          {
            todo: "Lidera con el ejemplo al abordar los conflictos de manera constructiva y transparente.",
            howto: "Muestra cómo se deben manejar los conflictos, mostrando empatía, escuchando activamente y buscando soluciones conjuntas."
          },
          {
            todo: "Establece un ambiente de trabajo basado en la confianza mutua y el respeto.",
            howto: "Fomenta relaciones sólidas entre los miembros del equipo para que se sientan cómodos compartiendo preocupaciones."
          },
          {
            todo: "Promueve la comunicación abierta y honesta entre los miembros del equipo.",
            howto: "Anima a los colaboradores a expresar sus opiniones de manera respetuosa y a dar retroalimentación constructiva."
          },
          {
            todo: "Proporciona recursos y herramientas que ayuden al equipo a abordar el conflicto de manera efectiva.",
            howto: "Ofrece capacitación y acceso a recursos que mejoren las habilidades de resolución de conflictos del equipo."
          },
          {
            todo: "Sigue monitoreando la dinámica del equipo y la gestión del conflicto de manera regular.",
            howto: "Mantén un ojo en la forma en que el equipo aborda el conflicto y toma medidas cuando sea necesario para mantener un ambiente saludable."
          }
        ],
        team_actions: [
          {
            todo: "Programa reuniones individuales para discutir las preocupaciones y necesidades de cada colaborador en relación con el conflicto.",
            howto: "Agenda reuniones uno a uno para fomentar la apertura y la comunicación."
          },
          {
            todo: "Proporciona retroalimentación específica sobre cómo cada colaborador puede abordar el conflicto de manera efectiva.",
            howto: "Ofrece consejos personalizados y ejemplos concretos para mejorar las habilidades de resolución de conflictos."
          },
          {
            todo: "Apoya el desarrollo de habilidades de resolución de conflictos de manera individualizada.",
            howto: "Identifica las necesidades de desarrollo de cada colaborador y proporciona recursos y capacitación adecuados."
          },
          {
            todo: "Fomenta la autoconfianza de cada colaborador en relación con la gestión del conflicto.",
            howto: "Anima a cada empleado a confiar en sus habilidades para abordar el conflicto de manera constructiva."
          },
          {
            todo: "Ofrece oportunidades para que cada colaborador participe en ejercicios de role-playing o simulaciones de conflictos.",
            howto: "Proporciona ejercicios prácticos que ayuden a los colaboradores a practicar la resolución de conflictos."
          },
          {
            todo: "Sigue proporcionando retroalimentación continua sobre el progreso de cada colaborador en la gestión del conflicto.",
            howto: "Comunica regularmente el avance hacia objetivos relacionados con el conflicto y brinda apoyo y orientación."
          },
          {
            todo: "Facilita la comunicación abierta y respetuosa entre colaboradores para abordar problemas de manera constructiva.",
            howto: "Promueve un ambiente en el que los colaboradores se sientan seguros al expresar preocupaciones y buscar soluciones juntos."
          },
          {
            todo: "Proporciona retroalimentación específica sobre el progreso hacia metas y objetivos individuales relacionados con el conflicto.",
            howto: "Comunica el avance de cada colaborador hacia sus metas y ofrece consejos para superar obstáculos."
          },
          {
            todo: "Continúa ofreciendo oportunidades para el desarrollo personal y profesional en relación con la gestión del conflicto.",
            howto: "Identifica programas de capacitación y recursos que ayuden a los colaboradores a mejorar sus habilidades."
          },
          {
            todo: "Mantén un canal de comunicación abierto con cada colaborador para que puedan expresar sus preocupaciones de manera confidencial.",
            howto: "Asegúrate de que los colaboradores sepan que pueden acudir a ti en cualquier momento para discutir problemas relacionados con el conflicto."
          }
        ],
        message: "Estimado líder, reconocer y abordar tu miedo al conflicto es un gran paso hacia un liderazgo más efectivo y un ambiente de trabajo más saludable. Tu voluntad de mejorar y aprender es encomiable. Con determinación y práctica, puedes convertirte en un líder que aborda el conflicto de manera constructiva, lo que conducirá a un equipo más fuerte y cohesionado. ¡Sigue adelante con confianza en tu capacidad para crecer y evolucionar!"
      },
      yellow: {
        leader_selfknowledge: [
          {
            todo: "Reconoce y acepta que tienes un nivel moderado de miedo al conflicto.",
            howto: "Reflexiona sobre situaciones pasadas en las que has evitado el conflicto y reconoce su impacto en el equipo."
          },
          {
            todo: "Investiga tus reacciones emocionales ante el conflicto y busca patrones en tu comportamiento.",
            howto: "Examina tus respuestas emocionales en situaciones conflictivas y cómo influyen en tu capacidad para abordar el conflicto."
          },
          {
            todo: "Busca retroalimentación de colegas, superiores y colaboradores sobre tu manejo del conflicto.",
            howto: "Pide a otros que compartan sus percepciones sobre cómo manejas las situaciones conflictivas."
          },
          {
            todo: "Practica la autoevaluación para comprender tus desencadenantes emocionales en situaciones de conflicto.",
            howto: "Reflexiona sobre las emociones que surgen durante el conflicto y cómo pueden influir en tu comportamiento."
          },
          {
            todo: "Investiga tus valores personales y cómo pueden estar relacionados con tu manejo del conflicto.",
            howto: "Considera cómo tus valores pueden impactar en tu disposición para abordar el conflicto y ajusta si es necesario."
          },
          {
            todo: "Busca oportunidades de desarrollo personalizado para mejorar tus habilidades en la gestión del conflicto.",
            howto: "Inscríbete en talleres, cursos o programas de entrenamiento en resolución de conflictos."
          },
          {
            todo: "Practica la empatía y la escucha activa para comprender las perspectivas de los demás en situaciones de conflicto.",
            howto: "Enfócate en entender las preocupaciones y necesidades de los demás antes de responder."
          },
          {
            todo: "Investiga tus patrones de comunicación y cómo pueden contribuir al miedo al conflicto.",
            howto: "Examina cómo te comunicas en situaciones de conflicto y busca formas de mejorar tus habilidades comunicativas."
          },
          {
            todo: "Mantén un registro de tus respuestas en situaciones de conflicto para identificar patrones y áreas de mejora.",
            howto: "Lleva un registro de tus reacciones emocionales y acciones durante los conflictos."
          },
          {
            todo: "Establece un plan personal de desarrollo para superar el miedo al conflicto y define metas específicas.",
            howto: "Define objetivos claros para abordar el conflicto de manera efectiva y revisa regularmente tu progreso."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica la importancia de abordar el conflicto de manera constructiva en el equipo.",
            howto: "Explica que el conflicto puede ser una fuente de crecimiento y mejora si se maneja adecuadamente."
          },
          {
            todo: "Establece expectativas claras de que el equipo debe abordar los conflictos abierta y respetuosamente.",
            howto: "Asegúrate de que todos comprendan que se espera que los conflictos se discutan y resuelvan adecuadamente."
          },
          {
            todo: "Implementa un proceso estructurado para gestionar el conflicto en el equipo.",
            howto: "Crea un procedimiento que guíe la resolución de conflictos de manera efectiva, incluyendo pasos y responsabilidades claras."
          },
          {
            todo: "Organiza talleres o ejercicios de desarrollo de habilidades para el equipo en relación con la gestión del conflicto.",
            howto: "Ofrece oportunidades de capacitación y práctica para que el equipo mejore sus habilidades en esta área."
          },
          {
            todo: "Fomenta la confianza y la apertura en el equipo para que los miembros se sientan cómodos expresando sus preocupaciones.",
            howto: "Crea un ambiente en el que los miembros del equipo se sientan seguros al hablar sobre temas conflictivos."
          },
          {
            todo: "Lidera con el ejemplo al abordar los conflictos de manera constructiva y transparente.",
            howto: "Muestra cómo se deben manejar los conflictos, mostrando empatía, escuchando activamente y buscando soluciones conjuntas."
          },
          {
            todo: "Establece un ambiente de trabajo basado en la confianza mutua y el respeto.",
            howto: "Fomenta relaciones sólidas entre los miembros del equipo para que se sientan cómodos compartiendo preocupaciones."
          },
          {
            todo: "Promueve la comunicación abierta y honesta entre los miembros del equipo.",
            howto: "Anima a los colaboradores a expresar sus opiniones de manera respetuosa y a dar retroalimentación constructiva."
          },
          {
            todo: "Proporciona recursos y herramientas que ayuden al equipo a abordar el conflicto de manera efectiva.",
            howto: "Ofrece capacitación y acceso a recursos que mejoren las habilidades de resolución de conflictos del equipo."
          },
          {
            todo: "Sigue monitoreando la dinámica del equipo y la gestión del conflicto de manera regular.",
            howto: "Mantén un ojo en la forma en que el equipo aborda el conflicto y toma medidas cuando sea necesario para mantener un ambiente saludable."
          }
        ],
        team_actions: [
          {
            todo: "Programa reuniones individuales para discutir las preocupaciones y necesidades de cada colaborador en relación con el conflicto.",
            howto: "Agenda reuniones uno a uno para fomentar la apertura y la comunicación."
          },
          {
            todo: "Proporciona retroalimentación específica sobre cómo cada colaborador puede abordar el conflicto de manera efectiva.",
            howto: "Ofrece consejos personalizados y ejemplos concretos para mejorar las habilidades de resolución de conflictos."
          },
          {
            todo: "Apoya el desarrollo de habilidades de resolución de conflictos de manera individualizada.",
            howto: "Identifica las necesidades de desarrollo de cada colaborador y proporciona recursos y capacitación adecuados."
          },
          {
            todo: "Fomenta la autoconfianza de cada colaborador en relación con la gestión del conflicto.",
            howto: "Anima a cada empleado a confiar en sus habilidades para abordar el conflicto de manera constructiva."
          },
          {
            todo: "Ofrece oportunidades para que cada colaborador participe en ejercicios de role-playing o simulaciones de conflictos.",
            howto: "Proporciona ejercicios prácticos que ayuden a los colaboradores a practicar la resolución de conflictos."
          },
          {
            todo: "Sigue proporcionando retroalimentación continua sobre el progreso de cada colaborador en la gestión del conflicto.",
            howto: "Comunica regularmente el avance hacia objetivos relacionados con el conflicto y brinda apoyo y orientación."
          },
          {
            todo: "Facilita la comunicación abierta y respetuosa entre colaboradores para abordar problemas de manera constructiva.",
            howto: "Promueve un ambiente en el que los colaboradores se sientan seguros al expresar preocupaciones y buscar soluciones juntos."
          },
          {
            todo: "Proporciona retroalimentación específica sobre el progreso hacia metas y objetivos individuales relacionados con el conflicto.",
            howto: "Comunica el avance de cada colaborador hacia sus metas y ofrece consejos para superar obstáculos."
          },
          {
            todo: "Continúa ofreciendo oportunidades para el desarrollo personal y profesional en relación con la gestión del conflicto.",
            howto: "Identifica programas de capacitación y recursos que ayuden a los colaboradores a mejorar sus habilidades."
          },
          {
            todo: "Mantén un canal de comunicación abierto con cada colaborador para que puedan expresar sus preocupaciones de manera confidencial.",
            howto: "Asegúrate de que los colaboradores sepan que pueden acudir a ti en cualquier momento para discutir problemas relacionados con el conflicto."
          }
        ],
        message: "Estimado líder, tu disposición para mejorar tu manejo del conflicto es un paso importante hacia un liderazgo más efectivo y un equipo más cohesionado. Aprovecha tus habilidades de autoevaluación y empatía para seguir creciendo en esta área. Con paciencia y práctica, estás en el camino correcto para convertirte en un líder que aborda el conflicto de manera constructiva y contribuye al éxito del equipo. ¡Sigue adelante con determinación y confianza!"
      },
      green: {
        leader_selfknowledge: [
          {
            todo: "Continúa reconociendo la importancia de abordar el conflicto de manera constructiva.",
            howto: "Mantén una mentalidad abierta y recuerda que el conflicto puede ser una oportunidad para el crecimiento y la mejora."
          },
          {
            todo: "Reflexiona sobre tus experiencias pasadas en la gestión del conflicto y celebra tus éxitos en este sentido.",
            howto: "Piensa en situaciones en las que manejaste el conflicto de manera efectiva y extrapola lecciones de esas experiencias."
          },
          {
            todo: "Fomenta la autoevaluación continua para seguir mejorando tus habilidades en la gestión del conflicto.",
            howto: "Reflexiona sobre tu propio comportamiento y busca áreas de mejora, incluso cuando todo parece estar funcionando bien."
          },
          {
            todo: "Sigue buscando retroalimentación de colegas, superiores y colaboradores sobre tu manejo del conflicto.",
            howto: "Pide opiniones sinceras sobre cómo manejas las situaciones conflictivas y usa esa información para crecer."
          },
          {
            todo: "Continúa practicando la empatía y la escucha activa para entender las perspectivas de los demás en situaciones de conflicto.",
            howto: "Siempre busca entender las preocupaciones y necesidades de los demás antes de tomar decisiones."
          },
          {
            todo: "Investiga tus patrones de comunicación en situaciones de conflicto y busca formas de mejorar tus habilidades.",
            howto: "Examina cómo te comunicas en situaciones de conflicto y sigue perfeccionando tus habilidades comunicativas."
          },
          {
            todo: "Fomenta la autoconfianza de tu equipo en relación con la gestión del conflicto.",
            howto: "Anima a tus colaboradores a confiar en sus habilidades para abordar el conflicto de manera constructiva."
          },
          {
            todo: "Sigue promoviendo un ambiente de trabajo basado en la confianza mutua y el respeto.",
            howto: "Fomenta relaciones sólidas entre los miembros del equipo y apoya un entorno en el que se sientan cómodos compartiendo preocupaciones."
          },
          {
            todo: "Mantén un canal de comunicación abierto con tu equipo para abordar cualquier problema de manera proactiva.",
            howto: "Anima a tus colaboradores a expresar sus preocupaciones de manera abierta y a buscar soluciones juntos."
          },
          {
            todo: "Continúa con tu desarrollo personal en la gestión del conflicto, manteniendo un enfoque en el crecimiento a largo plazo.",
            howto: "Sigue aprendiendo, asiste a talleres o cursos, y busca mentoría o consejo de otros líderes con experiencia en este campo."
          }
        ],
        leader_relation: [
          {
            todo: "Sigue comunicando la importancia de abordar el conflicto de manera constructiva en el equipo.",
            howto: "Refuerza la idea de que el conflicto puede ser una fuente de mejora si se maneja adecuadamente."
          },
          {
            todo: "Mantén expectativas claras de que el equipo debe abordar los conflictos abierta y respetuosamente.",
            howto: "Asegúrate de que todos comprendan que se espera que los conflictos se discutan y resuelvan adecuadamente."
          },
          {
            todo: "Continúa implementando un proceso estructurado para gestionar el conflicto en el equipo.",
            howto: "Sigue utilizando un procedimiento que guíe la resolución de conflictos de manera efectiva, incluyendo pasos y responsabilidades claras."
          },
          {
            todo: "Organiza talleres o ejercicios de desarrollo de habilidades para el equipo en relación con la gestión del conflicto.",
            howto: "Ofrece oportunidades de capacitación y práctica para que el equipo siga mejorando en esta área."
          },
          {
            todo: "Mantén la confianza y la apertura en el equipo para que los miembros se sientan cómodos expresando sus preocupaciones.",
            howto: "Continúa fomentando un ambiente donde los miembros del equipo se sientan seguros al hablar sobre temas conflictivos."
          },
          {
            todo: "Sigue liderando con el ejemplo al abordar los conflictos de manera constructiva y transparente.",
            howto: "Continúa mostrando cómo se deben manejar los conflictos, mostrando empatía, escuchando activamente y buscando soluciones conjuntas."
          },
          {
            todo: "Sigue promoviendo un ambiente de trabajo basado en la confianza mutua y el respeto.",
            howto: "Refuerza las relaciones sólidas entre los miembros del equipo y asegúrate de que se sientan cómodos compartiendo preocupaciones."
          },
          {
            todo: "Continúa promoviendo la comunicación abierta y honesta entre los miembros del equipo.",
            howto: "Anima a los colaboradores a expresar sus opiniones de manera respetuosa y a dar retroalimentación constructiva."
          },
          {
            todo: "Proporciona recursos y herramientas que ayuden al equipo a abordar el conflicto de manera efectiva.",
            howto: "Continúa ofreciendo capacitación y acceso a recursos que mejoren las habilidades de resolución de conflictos del equipo."
          },
          {
            todo: "Sigue monitoreando la dinámica del equipo y la gestión del conflicto de manera regular.",
            howto: "Mantén un ojo en la forma en que el equipo aborda el conflicto y toma medidas cuando sea necesario para mantener un ambiente saludable."
          }
        ],
        team_actions: [
          {
            todo: "Continúa programando reuniones individuales para discutir las preocupaciones y necesidades de cada colaborador en relación con el conflicto.",
            howto: "Continúa programando reuniones uno a uno para mantener una comunicación abierta."
          },
          {
            todo: "Sigue proporcionando retroalimentación específica sobre cómo cada colaborador puede abordar el conflicto de manera efectiva.",
            howto: "Continúa ofreciendo consejos personalizados y ejemplos concretos para mejorar las habilidades de resolución de conflictos."
          },
          {
            todo: "Continúa apoyando el desarrollo de habilidades de resolución de conflictos de manera individualizada.",
            howto: "Sigue identificando las necesidades de desarrollo de cada colaborador y proporcionando recursos y capacitación adecuados."
          },
          {
            todo: "Fomenta la autoconfianza de cada colaborador en relación con la gestión del conflicto.",
            howto: "Continúa animando a cada empleado a confiar en sus habilidades para abordar el conflicto de manera constructiva."
          },
          {
            todo: "Continúa ofreciendo oportunidades para que cada colaborador participe en ejercicios de role-playing o simulaciones de conflictos.",
            howto: "Mantén la práctica en resolución de conflictos a nivel individual."
          },
          {
            todo: "Continúa proporcionando retroalimentación continua sobre el progreso de cada colaborador en la gestión del conflicto.",
            howto: "Comunica regularmente el avance hacia objetivos relacionados con el conflicto y sigue brindando apoyo y orientación."
          },
          {
            todo: "Sigue facilitando la comunicación abierta y respetuosa entre colaboradores para abordar problemas de manera constructiva.",
            howto: "Continúa promoviendo un ambiente donde los colaboradores se sientan seguros al expresar preocupaciones y buscar soluciones juntos."
          },
          {
            todo: "Continúa proporcionando retroalimentación específica sobre el progreso hacia metas y objetivos individuales relacionados con el conflicto.",
            howto: "Comunica el avance de cada colaborador hacia sus metas y ofrece consejos para superar obstáculos."
          },
          {
            todo: "Continúa ofreciendo oportunidades para el desarrollo personal y profesional en relación con la gestión del conflicto.",
            howto: "Continúa identificando programas de capacitación y recursos que ayuden a los colaboradores a mejorar sus habilidades."
          },
          {
            todo: "Mantén un canal de comunicación abierto con cada colaborador para que puedan expresar sus preocupaciones de manera confidencial.",
            howto: "Continúa asegurándote de que los colaboradores sepan que pueden acudir a ti en cualquier momento para discutir problemas relacionados con el conflicto."
          }
        ],
        message: "Estimado líder, tu habilidad para abordar el conflicto de manera efectiva es un gran activo para tu equipo y organización. Continuar desarrollando estas habilidades fortalecerá aún más la cohesión de tu equipo y su capacidad para superar desafíos. Sigue adelante con tu enfoque proactivo y sigue liderando con el ejemplo. ¡Eres un modelo a seguir en la gestión del conflicto!"
      }
    },
    engagement: {
      red: {
        leader_selfknowledge: [
          {
            todo: "Reconoce y acepta que existe un problema de falta de compromiso en tu equipo.",
            howto: "Evalúa objetivamente la situación y admite que se necesita un cambio."
          },
          {
            todo: "Reflexiona sobre tu propio nivel de compromiso con los objetivos del equipo.",
            howto: "Pregúntate a ti mismo si estás demostrando un compromiso sólido y si estás sirviendo como ejemplo."
          },
          {
            todo: "Identifica tus propias acciones o comportamientos que pueden haber contribuido a la falta de compromiso.",
            howto: "Examina cómo tu liderazgo ha afectado la motivación y el compromiso del equipo."
          },
          {
            todo: "Investiga tus habilidades de comunicación y cómo pueden influir en el compromiso del equipo.",
            howto: "Evalúa si estás proporcionando información clara y motivadora sobre los objetivos y metas del equipo."
          },
          {
            todo: "Considera tus expectativas y si son realistas y alcanzables para el equipo.",
            howto: "Reflexiona sobre si los objetivos y metas que has establecido son apropiados y si has dado los recursos necesarios."
          },
          {
            todo: "Busca retroalimentación honesta de los miembros del equipo sobre cómo perciben la falta de compromiso.",
            howto: "Abre un canal de comunicación para que los colaboradores puedan expresar sus preocupaciones libremente."
          },
          {
            todo: "Investiga tus habilidades de liderazgo y cómo pueden afectar la motivación de los colaboradores.",
            howto: "Reflexiona sobre tu capacidad para inspirar y motivar a tu equipo."
          },
          {
            todo: "Explora tus propias motivaciones como líder y si estás alineado con los valores y objetivos del equipo.",
            howto: "Evalúa si estás liderando con pasión y compromiso hacia la misión del equipo."
          },
          {
            todo: "Busca oportunidades de desarrollo personal para mejorar tus habilidades en la gestión de equipos y la generación de compromiso.",
            howto: "Investiga programas de liderazgo y desarrollo que te ayuden a fortalecer tus habilidades."
          },
          {
            todo: "Comunica tu compromiso renovado para abordar la falta de compromiso en el equipo.",
            howto: "Explica a tu equipo que estás comprometido en resolver este problema y que trabajará en conjunto para lograrlo."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica la importancia del compromiso y la contribución individual al éxito del equipo.",
            howto: "Explica cómo el compromiso de cada miembro es esencial para alcanzar los objetivos."
          },
          {
            todo: "Establece expectativas claras de compromiso y responsabilidad para todos los miembros del equipo.",
            howto: "Asegúrate de que cada colaborador comprenda su papel y la importancia de su contribución."
          },
          {
            todo: "Organiza reuniones regulares para discutir los objetivos del equipo y cómo cada miembro puede contribuir.",
            howto: "Fomenta la alineación y la participación activa en la planificación y ejecución de proyectos."
          },
          {
            todo: "Promueve un ambiente de trabajo que fomente la motivación y el compromiso.",
            howto: "Crea un lugar donde los colaboradores se sientan valorados y reconocidos por sus contribuciones."
          },
          {
            todo: "Fomenta la comunicación abierta y la retroalimentación constructiva entre los miembros del equipo.",
            howto: "Anima a los colaboradores a expresar sus opiniones y preocupaciones de manera respetuosa."
          },
          {
            todo: "Proporciona recursos y herramientas que faciliten el trabajo en equipo y la colaboración.",
            howto: "Asegúrate de que el equipo tenga acceso a las herramientas y el apoyo necesario para desempeñar su trabajo de manera efectiva."
          },
          {
            todo: "Ofrece reconocimiento y recompensas por el compromiso y el desempeño excepcional.",
            howto: "Celebra los logros individuales y del equipo y reconoce públicamente las contribuciones."
          },
          {
            todo: "Sé un modelo a seguir al mostrar un compromiso sólido con los objetivos del equipo.",
            howto: "Demuestra tu pasión y dedicación a través de tus acciones y decisiones."
          },
          {
            todo: "Facilita la formación de relaciones sólidas entre los miembros del equipo.",
            howto: "Fomenta la colaboración y el compañerismo dentro del equipo."
          },
          {
            todo: "Establece un proceso para evaluar y medir el compromiso del equipo y realiza ajustes según sea necesario.",
            howto: "Utiliza encuestas, retroalimentación y métricas para evaluar el nivel de compromiso y tomar medidas para mejorarlo."
          }
        ],
        team_actions: [
          {
            todo: "Programa reuniones individuales para discutir el nivel de compromiso de cada colaborador y sus motivaciones.",
            howto: "Mantén conversaciones abiertas y comprensivas para entender sus perspectivas y preocupaciones."
          },
          {
            todo: "Proporciona retroalimentación específica sobre cómo cada colaborador puede contribuir más al equipo.",
            howto: "Ofrece orientación personalizada y establece metas claras."
          },
          {
            todo: "Apoya el desarrollo de habilidades específicas que ayuden a cada colaborador a contribuir de manera más efectiva.",
            howto: "Identifica áreas de mejora y ofrece oportunidades de capacitación."
          },
          {
            todo: "Fomenta la autoconfianza de cada colaborador en relación con su capacidad para contribuir al equipo.",
            howto: "Anima a cada empleado a confiar en sus habilidades y conocimientos."
          },
          {
            todo: "Ofrece oportunidades para que cada colaborador se involucre en proyectos que los apasionen y motiven.",
            howto: "Asigna tareas que se alineen con los intereses y fortalezas individuales."
          },
          {
            todo: "Sigue proporcionando retroalimentación constante y reconocimiento por las contribuciones de cada colaborador.",
            howto: "Destaca los logros individuales y celebra el esfuerzo y la dedicación."
          },
          {
            todo: "Facilita la resolución de cualquier conflicto o problema individual que pueda estar afectando el compromiso.",
            howto: "Escucha activamente y busca soluciones conjuntas con cada colaborador."
          },
          {
            todo: "Proporciona recursos y apoyo personalizado para ayudar a cada colaborador a superar obstáculos.",
            howto: "Asegúrate de que tengan acceso a las herramientas y recursos necesarios para realizar su trabajo de manera efectiva."
          },
          {
            todo: "Anima a cada colaborador a compartir sus metas personales y profesionales contigo.",
            howto: "Mantén un diálogo abierto sobre las aspiraciones y expectativas individuales."
          },
          {
            todo: "Continúa manteniendo un canal de comunicación abierto y accesible para cada colaborador.",
            howto: "Asegúrate de que se sientan cómodos acudiendo a ti con preocupaciones o ideas en cualquier momento."
          }
        ],
        message: "Querido líder, enfrentar la falta de compromiso en tu equipo es un desafío importante, pero con enfoque y determinación, puedes superarlo. Reconocer el problema y tomar medidas para abordarlo es el primer paso hacia el cambio positivo. Inspira a tu equipo con tu compromiso renovado y lidera con pasión. Recuerda que cada miembro del equipo tiene un papel crucial en el éxito del conjunto. Juntos, pueden lograr grandes cosas."
      },
      yellow: {
        leader_selfknowledge: [
          {
            todo: "Reflexiona sobre tu percepción de la falta de compromiso en el equipo y cómo esto puede afectar los resultados.",
            howto: "Tómate un tiempo para analizar la situación y sus posibles causas."
          },
          {
            todo: "Evalúa tu propio nivel de compromiso con los objetivos del equipo y cómo esto influye en los demás.",
            howto: "Considera si estás demostrando un compromiso sólido y si eres un modelo a seguir en este sentido."
          },
          {
            todo: "Analiza tus habilidades de comunicación y cómo pueden estar contribuyendo a la falta de compromiso.",
            howto: "Piensa en cómo estás transmitiendo los objetivos y metas del equipo a los miembros."
          },
          {
            todo: "Investiga tus expectativas y asegúrate de que sean realistas y alcanzables para el equipo.",
            howto: "Reflexiona sobre si los objetivos y metas que has establecido son apropiados y si has proporcionado los recursos necesarios."
          },
          {
            todo: "Busca retroalimentación de los miembros del equipo sobre cómo perciben la falta de compromiso.",
            howto: "Abre un canal de comunicación para que los colaboradores puedan expresar sus inquietudes."
          },
          {
            todo: "Evalúa tus habilidades de liderazgo y cómo pueden influir en la motivación y el compromiso del equipo.",
            howto: "Reflexiona sobre tu capacidad para inspirar y motivar a tu equipo."
          },
          {
            todo: "Investiga tus propias motivaciones como líder y si estás alineado con los valores y objetivos del equipo.",
            howto: "Evalúa si estás liderando con pasión y compromiso hacia la misión del equipo."
          },
          {
            todo: "Considera la posibilidad de buscar oportunidades de desarrollo personal para mejorar tus habilidades en la generación de compromiso.",
            howto: "Investiga programas de liderazgo y desarrollo que te ayuden a fortalecer tus habilidades."
          },
          {
            todo: "Comunica a tu equipo tu interés en abordar la falta de compromiso y tu compromiso con el cambio.",
            howto: "Explica que estás dispuesto a trabajar juntos para mejorar la situación."
          },
          {
            todo: "Establece un plan personal de desarrollo para mejorar tus habilidades en la generación de compromiso.",
            howto: "Define metas claras y pasos concretos para convertirte en un líder más efectivo en este sentido."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica la importancia del compromiso y la contribución individual al éxito del equipo.",
            howto: "Destaca cómo el compromiso de cada miembro es fundamental para alcanzar los objetivos."
          },
          {
            todo: "Establece expectativas claras de compromiso y responsabilidad para todos los miembros del equipo.",
            howto: "Asegúrate de que cada colaborador comprenda su rol y la importancia de su contribución."
          },
          {
            todo: "Organiza reuniones regulares para discutir los objetivos del equipo y cómo cada miembro puede contribuir.",
            howto: "Fomenta la alineación y la participación activa en la planificación y ejecución de proyectos."
          },
          {
            todo: "Promueve un ambiente de trabajo que fomente la motivación y el compromiso.",
            howto: "Crea un entorno donde los colaboradores se sientan valorados y reconocidos por sus contribuciones."
          },
          {
            todo: "Fomenta la comunicación abierta y la retroalimentación constructiva entre los miembros del equipo.",
            howto: "Anima a los colaboradores a expresar sus opiniones y preocupaciones de manera respetuosa."
          },
          {
            todo: "Proporciona recursos y herramientas que faciliten el trabajo en equipo y la colaboración.",
            howto: "Asegúrate de que el equipo tenga acceso a las herramientas y el apoyo necesarios para realizar su trabajo de manera efectiva."
          },
          {
            todo: "Ofrece reconocimiento y recompensas por el compromiso y el desempeño excepcional.",
            howto: "Celebra los logros individuales y del equipo y reconoce públicamente las contribuciones."
          },
          {
            todo: "Sé un modelo a seguir al mostrar un compromiso sólido con los objetivos del equipo.",
            howto: "Demuestra tu pasión y dedicación a través de tus acciones y decisiones."
          },
          {
            todo: "Facilita la formación de relaciones sólidas entre los miembros del equipo.",
            howto: "Fomenta la colaboración y el compañerismo dentro del equipo."
          },
          {
            todo: "Establece un proceso para evaluar y medir el compromiso del equipo y realiza ajustes según sea necesario.",
            howto: "Utiliza encuestas, retroalimentación y métricas para evaluar el nivel de compromiso y tomar medidas para mejorarlo."
          }
        ],
        team_actions: [
          {
            todo: "Programa reuniones individuales para discutir el nivel de compromiso de cada colaborador y sus motivaciones.",
            howto: "Mantén conversaciones abiertas y comprensivas para entender sus perspectivas y preocupaciones."
          },
          {
            todo: "Proporciona retroalimentación específica sobre cómo cada colaborador puede contribuir más al equipo.",
            howto: "Ofrece orientación personalizada y establece metas claras."
          },
          {
            todo: "Apoya el desarrollo de habilidades específicas que ayuden a cada colaborador a contribuir de manera más efectiva.",
            howto: "Identifica áreas de mejora y ofrece oportunidades de capacitación."
          },
          {
            todo: "Fomenta la autoconfianza de cada colaborador en relación con su capacidad para contribuir al equipo.",
            howto: "Anima a cada empleado a confiar en sus habilidades y conocimientos."
          },
          {
            todo: "Ofrece oportunidades para que cada colaborador se involucre en proyectos que los apasionen y motiven.",
            howto: "Asigna tareas que se alineen con los intereses y fortalezas individuales."
          },
          {
            todo: "Sigue proporcionando retroalimentación constante y reconocimiento por las contribuciones de cada colaborador.",
            howto: "Destaca los logros individuales y celebra el esfuerzo y la dedicación."
          },
          {
            todo: "Facilita la resolución de cualquier conflicto o problema individual que pueda estar afectando el compromiso.",
            howto: "Escucha activamente y busca soluciones conjuntas con cada colaborador."
          },
          {
            todo: "Proporciona recursos y apoyo personalizado para ayudar a cada colaborador a superar obstáculos.",
            howto: "Asegúrate de que tengan acceso a las herramientas y recursos necesarios para realizar su trabajo de manera efectiva."
          },
          {
            todo: "Anima a cada colaborador a compartir sus metas personales y profesionales contigo.",
            howto: "Mantén un diálogo abierto sobre las aspiraciones y expectativas individuales."
          },
          {
            todo: "Continúa manteniendo un canal de comunicación abierto y accesible para cada colaborador.",
            howto: "Asegúrate de que se sientan cómodos acudiendo a ti con preocupaciones o ideas en cualquier momento."
          }
        ],
        message: "Estimado líder, tu reconocimiento de la falta de compromiso en tu equipo es un paso importante hacia el cambio. Con un enfoque positivo y una determinación constante, puedes lograr una mejora significativa en el compromiso de tu equipo. Sé paciente contigo mismo y con tus colaboradores mientras trabajan juntos en esta transformación. Cada pequeño avance nos acerca al éxito. ¡Sigue adelante y lidera con pasión!"
      },
      green: {
        leader_selfknowledge: [
          {
            todo: "Reflexiona sobre cómo has contribuido al alto nivel de compromiso en tu equipo.",
            howto: "Reconoce tus acciones y decisiones que han fomentado un ambiente comprometido."
          },
          {
            todo: "Continúa evaluando tu propio nivel de compromiso con los objetivos del equipo y mantén tu pasión.",
            howto: "Sigue siendo un modelo a seguir en términos de dedicación y motivación."
          },
          {
            todo: "Sigue mejorando tus habilidades de comunicación y cómo influyen positivamente en el compromiso del equipo.",
            howto: "Mantén una comunicación abierta y efectiva con el equipo."
          },
          {
            todo: "Continúa estableciendo expectativas realistas y alcanzables para el equipo.",
            howto: "Asegúrate de que los objetivos y metas estén alineados con los recursos disponibles."
          },
          {
            todo: "Busca regularmente retroalimentación de los miembros del equipo sobre su nivel de compromiso y cómo pueden mejorarlo aún más.",
            howto: "Fomenta un ambiente donde los colaboradores se sientan cómodos compartiendo sus opiniones."
          },
          {
            todo: "Sigue evaluando y mejorando tus habilidades de liderazgo en términos de motivación y compromiso.",
            howto: "Investiga nuevas prácticas de liderazgo y busca oportunidades de desarrollo."
          },
          {
            todo: "Mantén tu pasión y compromiso con los valores y objetivos del equipo.",
            howto: "Comunica regularmente la importancia de la misión del equipo y cómo cada uno contribuye a ella."
          },
          {
            todo: "Sigue apoyando el desarrollo personal y profesional de los colaboradores en relación con el compromiso.",
            howto: "Identifica oportunidades de crecimiento y capacitación específicas."
          },
          {
            todo: "Comunica regularmente a tu equipo tu compromiso continuo con el alto nivel de compromiso.",
            howto: "Demuestra que estás comprometido con mantener este estándar."
          },
          {
            todo: "Continúa aprendiendo y creciendo como líder en términos de generación de compromiso.",
            howto: "Investiga las mejores prácticas y busca mentores o consejeros que puedan ayudarte a seguir mejorando."
          }
        ],
        leader_relation: [
          {
            todo: "Sigue comunicando la importancia del compromiso y la contribución individual al éxito del equipo.",
            howto: "Refuerza regularmente la idea de que el compromiso es fundamental para alcanzar los objetivos."
          },
          {
            todo: "Mantén expectativas claras de compromiso y responsabilidad para todos los miembros del equipo.",
            howto: "Asegúrate de que cada colaborador comprenda su papel y la importancia de su contribución."
          },
          {
            todo: "Continúa organizando reuniones regulares para discutir los objetivos del equipo y cómo cada miembro puede contribuir.",
            howto: "Fomenta la alineación y la participación activa en la planificación y ejecución de proyectos."
          },
          {
            todo: "Sigue promoviendo un ambiente de trabajo que fomente la motivación y el compromiso.",
            howto: "Crea un lugar donde los colaboradores se sientan valorados y reconocidos por sus contribuciones."
          },
          {
            todo: "Fomenta la comunicación abierta y la retroalimentación constructiva entre los miembros del equipo.",
            howto: "Anima a los colaboradores a expresar sus opiniones y preocupaciones de manera respetuosa."
          },
          {
            todo: "Continúa proporcionando recursos y herramientas que faciliten el trabajo en equipo y la colaboración.",
            howto: "Asegúrate de que el equipo tenga acceso a las herramientas y el apoyo necesarios para realizar su trabajo de manera efectiva."
          },
          {
            todo: "Sigue ofreciendo reconocimiento y recompensas por el compromiso y el desempeño excepcional.",
            howto: "Celebra los logros individuales y del equipo y reconoce públicamente las contribuciones."
          },
          {
            todo: "Sigue siendo un modelo a seguir al mostrar un compromiso sólido con los objetivos del equipo.",
            howto: "Demuestra tu pasión y dedicación a través de tus acciones y decisiones."
          },
          {
            todo: "Continúa facilitando la formación de relaciones sólidas entre los miembros del equipo.",
            howto: "Fomenta la colaboración y el compañerismo dentro del equipo."
          },
          {
            todo: "Establece un proceso regular de evaluación y medición del compromiso del equipo para asegurarte de que se mantenga en un alto nivel.",
            howto: "Utiliza encuestas, retroalimentación y métricas para evaluar y mejorar el compromiso continuamente."
          }
        ],
        team_actions: [
          {
            todo: "Sigue manteniendo reuniones individuales para discutir el compromiso de cada colaborador y cómo pueden mantenerlo alto.",
            howto: "Mantén conversaciones regulares para conocer sus perspectivas y expectativas."
          },
          {
            todo: "Continúa proporcionando retroalimentación específica sobre cómo cada colaborador puede mantener y mejorar su compromiso.",
            howto: "Ofrece orientación personalizada y establece metas claras."
          },
          {
            todo: "Apoya el desarrollo continuo de habilidades específicas que ayuden a cada colaborador a contribuir de manera efectiva y mantener su compromiso.",
            howto: "Identifica áreas de mejora y ofrece oportunidades de capacitación."
          },
          {
            todo: "Sigue fomentando la autoconfianza de cada colaborador en relación con su capacidad para contribuir al equipo.",
            howto: "Anima a cada empleado a confiar en sus habilidades y conocimientos."
          },
          {
            todo: "Ofrece oportunidades regulares para que cada colaborador se involucre en proyectos que los apasionen y motiven.",
            howto: "Asigna tareas que se alineen con los intereses y fortalezas individuales."
          },
          {
            todo: "Continúa proporcionando retroalimentación constante y reconocimiento por las contribuciones de cada colaborador.",
            howto: "Destaca los logros individuales y celebra el esfuerzo y la dedicación."
          },
          {
            todo: "Facilita la resolución de cualquier conflicto o problema individual que pueda afectar el compromiso.",
            howto: "Escucha activamente y busca soluciones conjuntas con cada colaborador."
          },
          {
            todo: "Sigue proporcionando recursos y apoyo personalizado para ayudar a cada colaborador a superar obstáculos.",
            howto: "Asegúrate de que tengan acceso a las herramientas y recursos necesarios para realizar su trabajo de manera efectiva."
          },
          {
            todo: "Anima a cada colaborador a compartir sus metas personales y profesionales contigo y a mantener un diálogo abierto sobre sus aspiraciones.",
            howto: "Mantén un interés genuino en su desarrollo y crecimiento."
          },
          {
            todo: "Continúa manteniendo un canal de comunicación abierto y accesible para cada colaborador.",
            howto: "Asegúrate de que se sientan cómodos acudiendo a ti con preocupaciones o ideas en cualquier momento."
          }
        ],
        message: "Querido líder, tu equipo ha alcanzado un nivel impresionante de compromiso, y esto es un logro significativo. Tu liderazgo sólido y tu dedicación han sido fundamentales para alcanzar este éxito. Continúa nutriendo esta cultura de compromiso y liderando con pasión. Juntos, pueden alcanzar aún mayores alturas. ¡Felicidades por tu liderazgo ejemplar!"
      }
    },
    responsability: {
      red: {
        leader_selfknowledge: [
          {
            todo: "Reflexiona sobre las razones detrás de tu tendencia a evitar responsabilidades y cómo esto afecta al equipo.",
            howto: "Identifica si hay miedos, inseguridades o falta de confianza que puedan estar contribuyendo a este comportamiento."
          },
          {
            todo: "Reconoce la importancia de asumir la responsabilidad como líder y su impacto en el equipo.",
            howto: "Comprende que tu ejemplo influye en cómo los demás enfrentan sus responsabilidades."
          },
          {
            todo: "Evalúa tus habilidades de comunicación y si estás evitando conversaciones difíciles o responsabilidades incómodas.",
            howto: "Practica la comunicación asertiva y busca desarrollar la habilidad de abordar problemas directamente."
          },
          {
            todo: "Investiga si tienes expectativas poco claras o poco realistas para el equipo.",
            howto: "Asegúrate de que los objetivos y metas sean específicos, medibles y alcanzables."
          },
          {
            todo: "Busca retroalimentación honesta de los miembros del equipo sobre cómo perciben tu evitación de responsabilidades.",
            howto: "Fomenta un ambiente donde los colaboradores puedan expresar sus opiniones sin temor a represalias."
          },
          {
            todo: "Evalúa si tienes una tendencia a culpar a otros o externalizar la responsabilidad.",
            howto: "Asume la responsabilidad por tus decisiones y acciones."
          },
          {
            todo: "Investiga tus motivaciones como líder y si estás alineado con los valores y objetivos del equipo.",
            howto: "Refuerza tu compromiso con la misión del equipo y busca reenfocarte en su éxito."
          },
          {
            todo: "Considera la posibilidad de buscar apoyo externo, como un mentor o un coach, para abordar este comportamiento.",
            howto: "Un tercero puede proporcionar una perspectiva objetiva y consejos para el crecimiento."
          },
          {
            todo: "Comunica a tu equipo que estás trabajando en superar esta tendencia y asumir más responsabilidades.",
            howto: "Transmite tu compromiso con el cambio y la mejora."
          },
          {
            todo: "Establece un plan de desarrollo personal para mejorar tus habilidades de liderazgo y asumir responsabilidades.",
            howto: "Define metas concretas y pasos para cambiar tu comportamiento."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica claramente la importancia de la responsabilidad individual y colectiva en el éxito del equipo.",
            howto: "Destaca cómo asumir responsabilidades contribuye al logro de los objetivos."
          },
          {
            todo: "Establece expectativas claras sobre la asunción de responsabilidades y la rendición de cuentas para todos los miembros del equipo.",
            howto: "Asegúrate de que cada colaborador entienda su papel y su responsabilidad en el logro de metas."
          },
          {
            todo: "Organiza reuniones regulares para discutir las responsabilidades y cómo cada miembro puede contribuir.",
            howto: "Facilita discusiones sobre la distribución de tareas y la colaboración en proyectos."
          },
          {
            todo: "Promueve un ambiente de trabajo donde la responsabilidad sea valorada y recompensada.",
            howto: "Reconoce públicamente los logros de aquellos que asumen responsabilidades y las llevan a cabo con éxito."
          },
          {
            todo: "Fomenta una comunicación abierta y la retroalimentación constructiva entre los miembros del equipo.",
            howto: "Anima a los colaboradores a hablar sobre responsabilidades y preocupaciones de manera abierta."
          },
          {
            todo: "Proporciona recursos y herramientas que faciliten la asunción de responsabilidades y el trabajo en equipo.",
            howto: "Asegúrate de que el equipo tenga acceso a las herramientas y el apoyo necesarios."
          },
          {
            todo: "Ofrece reconocimiento y recompensas por la asunción de responsabilidades y el desempeño excepcional.",
            howto: "Celebra los logros individuales y del equipo relacionados con la responsabilidad."
          },
          {
            todo: "Sé un modelo a seguir al asumir tus propias responsabilidades y demostrar compromiso con el equipo.",
            howto: "Demuestra que estás dispuesto a hacer lo que esperas de los demás."
          },
          {
            todo: "Facilita la formación de relaciones sólidas entre los miembros del equipo para promover la colaboración y el apoyo mutuo.",
            howto: "Promueve la comunicación abierta y la colaboración en proyectos."
          },
          {
            todo: "Establece un proceso regular de evaluación y revisión de las responsabilidades y tareas del equipo.",
            howto: "Asegúrate de que las responsabilidades estén alineadas con los objetivos y sean distribuidas de manera equitativa."
          }
        ],
        team_actions: [
          {
            todo: "Mantén reuniones individuales regulares para discutir la asunción de responsabilidades de cada colaborador y cómo pueden mejorar en este aspecto.",
            howto: "Mantén un diálogo abierto sobre sus fortalezas y áreas de mejora."
          },
          {
            todo: "Proporciona retroalimentación específica sobre cómo cada colaborador puede asumir más responsabilidades de manera efectiva.",
            howto: "Ofrece orientación personalizada y establece metas claras relacionadas con la responsabilidad."
          },
          {
            todo: "Apoya el desarrollo de habilidades específicas que ayuden a cada colaborador a asumir responsabilidades y liderar proyectos.",
            howto: "Identifica áreas de mejora y ofrece oportunidades de capacitación."
          },
          {
            todo: "Fomenta la confianza de cada colaborador en su capacidad para asumir y cumplir con responsabilidades.",
            howto: "Reconoce sus logros y ofrece refuerzo positivo."
          },
          {
            todo: "Proporciona oportunidades para que cada colaborador\r\n\r\n asuma roles de liderazgo en proyectos y equipos.",
            howto: "Designa tareas que permitan a los colaboradores liderar y tomar decisiones."
          },
          {
            todo: "Establece expectativas claras sobre la rendición de cuentas y la asunción de responsabilidades individuales.",
            howto: "Comunica lo que se espera de cada colaborador y cómo se evaluará su desempeño."
          },
          {
            todo: "Facilita la resolución de conflictos o problemas que puedan surgir debido a la evitación de responsabilidades.",
            howto: "Escucha las preocupaciones y busca soluciones con cada colaborador."
          },
          {
            todo: "Ofrece apoyo personalizado y recursos adicionales para ayudar a los colaboradores a superar cualquier obstáculo en la asunción de responsabilidades.",
            howto: "Asegúrate de que tengan acceso a lo que necesitan para llevar a cabo sus tareas."
          },
          {
            todo: "Anima a cada colaborador a establecer metas de desarrollo relacionadas con la responsabilidad y el liderazgo.",
            howto: "Mantén un seguimiento de su progreso y ofréceles oportunidades para crecer."
          },
          {
            todo: "Mantén un canal de comunicación abierto y accesible para que cada colaborador pueda hablar sobre sus desafíos y preocupaciones en relación con las responsabilidades.",
            howto: "Asegúrate de que se sientan cómodos acudiendo a ti con cualquier problema."
          }
        ],
        message: "Estimado líder, reconocer la evitación de responsabilidades en tu equipo es el primer paso hacia un cambio positivo. Aunque enfrentar esta debilidad puede ser desafiante, tienes el poder de superarla y guiar a tu equipo hacia un mayor éxito. Mantén un enfoque positivo y persistente en la asunción de responsabilidades, y verás cómo tu equipo florece. ¡Adelante, líder valiente!"
      },
      yellow: {
        leader_selfknowledge: [
          {
            todo: "Reflexiona sobre las ocasiones en las que has evitado asumir responsabilidades en el pasado y las razones detrás de ello.",
            howto: "Identifica patrones de comportamiento y las situaciones que te hacen sentir incómodo."
          },
          {
            todo: "Reconoce la importancia de asumir la responsabilidad como líder y su impacto en la motivación del equipo.",
            howto: "Comprende que tu ejemplo es fundamental para el comportamiento del equipo."
          },
          {
            todo: "Evalúa si evitas responsabilidades por temor a errores o a la crítica.",
            howto: "Trabaja en fortalecer tu autoconfianza y capacidad para manejar situaciones difíciles."
          },
          {
            todo: "Investiga si tienes expectativas poco claras o poco realistas para el equipo que pueden estar contribuyendo a esta tendencia.",
            howto: "Asegúrate de que los objetivos y metas sean específicos, medibles y alcanzables."
          },
          {
            todo: "Busca retroalimentación honesta de los miembros del equipo sobre cómo perciben tu tendencia a evitar responsabilidades.",
            howto: "Crea un ambiente donde los colaboradores se sientan cómodos compartiendo sus opiniones."
          },
          {
            todo: "Evalúa si externalizas la responsabilidad o culpas a otros con frecuencia.",
            howto: "Asume la responsabilidad por tus decisiones y acciones."
          },
          {
            todo: "Investiga tus motivaciones como líder y si estás alineado con los valores y objetivos del equipo.",
            howto: "Refuerza tu compromiso con la misión del equipo y busca reenfocarte en su éxito."
          },
          {
            todo: "Considera la posibilidad de buscar apoyo externo, como un mentor o un coach, para abordar este comportamiento.",
            howto: "Un tercero puede proporcionar una perspectiva objetiva y consejos para el crecimiento."
          },
          {
            todo: "Comunica a tu equipo que estás trabajando en superar esta tendencia y asumir más responsabilidades.",
            howto: "Transmite tu compromiso con el cambio y la mejora."
          },
          {
            todo: "Establece un plan de desarrollo personal para mejorar tus habilidades de liderazgo y asumir más responsabilidades.",
            howto: "Define metas concretas y pasos para cambiar tu comportamiento."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica claramente la importancia de la responsabilidad individual y colectiva en el éxito del equipo.",
            howto: "Destaca cómo asumir responsabilidades contribuye al logro de los objetivos."
          },
          {
            todo: "Establece expectativas claras sobre la asunción de responsabilidades y la rendición de cuentas para todos los miembros del equipo.",
            howto: "Asegúrate de que cada colaborador entienda su papel y su responsabilidad en el logro de metas."
          },
          {
            todo: "Organiza reuniones regulares para discutir las responsabilidades y cómo cada miembro puede contribuir.",
            howto: "Facilita discusiones sobre la distribución de tareas y la colaboración en proyectos."
          },
          {
            todo: "Promueve un ambiente de trabajo donde la responsabilidad sea valorada y recompensada.",
            howto: "Reconoce públicamente los logros de aquellos que asumen responsabilidades y las llevan a cabo con éxito."
          },
          {
            todo: "Fomenta una comunicación abierta y la retroalimentación constructiva entre los miembros del equipo.",
            howto: "Anima a los colaboradores a hablar sobre responsabilidades y preocupaciones de manera abierta."
          },
          {
            todo: "Proporciona recursos y herramientas que faciliten la asunción de responsabilidades y el trabajo en equipo.",
            howto: "Asegúrate de que el equipo tenga acceso a las herramientas y el apoyo necesarios."
          },
          {
            todo: "Ofrece reconocimiento y recompensas por la asunción de responsabilidades y el desempeño excepcional.",
            howto: "Celebra los logros individuales y del equipo relacionados con la responsabilidad."
          },
          {
            todo: "Sé un modelo a seguir al asumir tus propias responsabilidades y demostrar compromiso con el equipo.",
            howto: "Demuestra que estás dispuesto a hacer lo que esperas de los demás."
          },
          {
            todo: "Facilita la formación de relaciones sólidas entre los miembros del equipo para promover la colaboración y el apoyo mutuo.",
            howto: "Promueve la comunicación abierta y la colaboración en proyectos."
          },
          {
            todo: "Establece un proceso regular de evaluación y revisión de las responsabilidades y tareas del equipo.",
            howto: "Asegúrate de que las responsabilidades estén alineadas con los objetivos y sean distribuidas de manera equitativa."
          }
        ],
        team_actions: [
          {
            todo: "Mantén reuniones individuales regulares para discutir la asunción de responsabilidades de cada colaborador y cómo pueden mejorar en este aspecto.",
            howto: "Mantén un diálogo abierto sobre sus fortalezas y áreas de mejora."
          },
          {
            todo: "Proporciona retroalimentación específica sobre cómo cada colaborador puede asumir más responsabilidades de manera efectiva.",
            howto: "Ofrece orientación personalizada y establece metas claras relacionadas con la responsabilidad."
          },
          {
            todo: "Apoya el desarrollo de habilidades específicas que ayuden a cada colaborador a asumir responsabilidades y liderar proyectos.",
            howto: "Identifica áreas de mejora y ofrece oportunidades de capacitación."
          },
          {
            todo: "Fomenta la confianza de cada colaborador en su capacidad para asumir y cumplir con responsabilidades.",
            howto: "Reconoce sus logros y ofrece refuerzo positivo."
          },
          {
            todo: "Proporciona oportunidades para que cada colaborador asuma roles de liderazgo en proyectos\r\n\r\n y equipos.",
            howto: "Designa tareas que permitan a los colaboradores liderar y tomar decisiones."
          },
          {
            todo: "Establece expectativas claras sobre la rendición de cuentas y la asunción de responsabilidades individuales.",
            howto: "Comunica lo que se espera de cada colaborador y cómo se evaluará su desempeño."
          },
          {
            todo: "Facilita la resolución de conflictos o problemas que puedan surgir debido a la evitación de responsabilidades.",
            howto: "Escucha las preocupaciones y busca soluciones con cada colaborador."
          },
          {
            todo: "Ofrece apoyo personalizado y recursos adicionales para ayudar a los colaboradores a superar cualquier obstáculo en la asunción de responsabilidades.",
            howto: "Asegúrate de que tengan acceso a lo que necesitan para llevar a cabo sus tareas."
          },
          {
            todo: "Anima a cada colaborador a establecer metas de desarrollo relacionadas con la responsabilidad y el liderazgo.",
            howto: "Mantén un seguimiento de su progreso y ofréceles oportunidades para crecer."
          },
          {
            todo: "Mantén un canal de comunicación abierto y accesible para que cada colaborador pueda hablar sobre sus desafíos y preocupaciones en relación con las responsabilidades.",
            howto: "Asegúrate de que se sientan cómodos acudiendo a ti con cualquier problema."
          }
        ],
        message: "Estimado líder, tu nivel amarillo en la evitación de responsabilidades es una oportunidad para crecer y mejorar. Reconocer esta área de desarrollo es un paso valiente. Con tu compromiso y esfuerzo, puedes avanzar hacia un liderazgo más fuerte y efectivo. Inspira a tu equipo con tu determinación y lidera con el ejemplo. ¡Sigamos trabajando juntos para alcanzar el éxito!"
      },
      green: {
        leader_selfknowledge: [
          {
            todo: "Continúa reflexionando sobre tu liderazgo y cómo tu comportamiento afecta a tu equipo.",
            howto: "Sigue siendo consciente de tus acciones y su impacto en los demás."
          },
          {
            todo: "Mantén una actitud abierta a la retroalimentación y el aprendizaje constante.",
            howto: "Escucha a tu equipo y busca oportunidades para mejorar continuamente."
          },
          {
            todo: "Sigue evaluando tus habilidades de comunicación y busca mejorar constantemente tus capacidades para abordar conversaciones difíciles.",
            howto: "Practica la comunicación asertiva y busca el desarrollo de habilidades de escucha activa."
          },
          {
            todo: "Mantén expectativas claras y realistas para ti mismo y tu equipo.",
            howto: "Asegúrate de que los objetivos y metas sean específicos, medibles y alcanzables."
          },
          {
            todo: "Continúa buscando retroalimentación honesta de los miembros del equipo sobre tu liderazgo y comportamiento.",
            howto: "Fomenta un ambiente donde los colaboradores se sientan cómodos compartiendo sus opiniones."
          },
          {
            todo: "Asegúrate de no culpar a otros o externalizar la responsabilidad, incluso en situaciones desafiantes.",
            howto: "Asume la responsabilidad por tus decisiones y acciones."
          },
          {
            todo: "Sigue alineando tus valores y objetivos personales con los del equipo y la organización.",
            howto: "Refuerza tu compromiso con la misión del equipo."
          },
          {
            todo: "Continúa aprovechando el apoyo externo, como un mentor o un coach, para seguir creciendo como líder.",
            howto: "Utiliza la perspectiva objetiva de un tercero para seguir mejorando."
          },
          {
            todo: "Comunica a tu equipo que estás comprometido con el desarrollo constante de tus habilidades de liderazgo.",
            howto: "Refuerza tu dedicación al crecimiento y desarrollo tanto personal como del equipo."
          },
          {
            todo: "Sigue desarrollando un plan de desarrollo personal para fortalecer tus habilidades de liderazgo y asumir más responsabilidades.",
            howto: "Define metas claras y establece un camino para alcanzarlas."
          }
        ],
        leader_relation: [
          {
            todo: "Continúa comunicando la importancia de la responsabilidad individual y colectiva en el éxito del equipo.",
            howto: "Resalta cómo asumir responsabilidades contribuye al logro de los objetivos."
          },
          {
            todo: "Mantén expectativas claras sobre la asunción de responsabilidades y la rendición de cuentas para todos los miembros del equipo.",
            howto: "Asegúrate de que cada colaborador comprenda su papel y responsabilidad en el logro de metas."
          },
          {
            todo: "Sigue organizando reuniones regulares para discutir las responsabilidades y cómo cada miembro puede contribuir.",
            howto: "Facilita discusiones sobre la distribución de tareas y la colaboración en proyectos."
          },
          {
            todo: "Continúa promoviendo un ambiente de trabajo donde la responsabilidad sea valorada y recompensada.",
            howto: "Reconoce públicamente los logros de aquellos que asumen responsabilidades y las llevan a cabo con éxito."
          },
          {
            todo: "Fomenta una comunicación abierta y la retroalimentación constructiva entre los miembros del equipo.",
            howto: "Anima a los colaboradores a hablar sobre responsabilidades y preocupaciones de manera abierta."
          },
          {
            todo: "Sigue proporcionando recursos y herramientas que faciliten la asunción de responsabilidades y el trabajo en equipo.",
            howto: "Asegúrate de que el equipo tenga acceso a las herramientas y el apoyo necesarios."
          },
          {
            todo: "Ofrece reconocimiento y recompensas por la asunción de responsabilidades y el desempeño excepcional.",
            howto: "Celebra los logros individuales y del equipo relacionados con la responsabilidad."
          },
          {
            todo: "Continúa siendo un modelo a seguir al asumir tus propias responsabilidades y demostrar compromiso con el equipo.",
            howto: "Demuestra que estás dispuesto a hacer lo que esperas de los demás."
          },
          {
            todo: "Facilita la formación de relaciones sólidas entre los miembros del equipo para promover la colaboración y el apoyo mutuo.",
            howto: "Promueve la comunicación abierta y la colaboración en proyectos."
          },
          {
            todo: "Sigue estableciendo un proceso regular de evaluación y revisión de las responsabilidades y tareas del equipo.",
            howto: "Asegúrate de que las responsabilidades estén alineadas con los objetivos y sean distribuidas de manera equitativa."
          }
        ],
        team_actions: [
          {
            todo: "Continúa manteniendo reuniones individuales regulares para discutir la asunción de responsabilidades de cada colaborador y cómo pueden seguir mejorando.",
            howto: "Mantén un diálogo abierto sobre sus fortalezas y áreas de mejora."
          },
          {
            todo: "Sigue proporcionando retroalimentación específica sobre cómo cada colaborador puede asumir más responsabilidades de manera efectiva.",
            howto: "Ofrece orientación personalizada y establece metas claras relacionadas con la responsabilidad."
          },
          {
            todo: "Apoya el desarrollo continuo de habilidades específicas que ayuden a cada colaborador a asumir responsabilidades y liderar proyectos.",
            howto: "Identifica áreas de mejora y ofrece oportunidades de capacitación."
          },
          {
            todo: "Continúa fomentando la confianza de cada colaborador en su capacidad para asumir y cumplir con responsabilidades.",
            howto: "Reconoce sus logros y ofrece refuerzo positivo."
          },
          {
            todo: "Sigue proporcionando oportunidades para que cada colaborador asuma roles de liderazgo en proyectos y equipos.",
            howto: "Designa tareas que les permitan liderar y tomar decisiones."
          },
          {
            todo: "Mantén expectativas claras sobre la rendición de cuentas y la asunción de responsabilidades individuales.",
            howto: "Comunica lo que se espera de cada colaborador y cómo se evaluará su desempeño."
          },
          {
            todo: "Continúa facilitando la resolución de conflictos o problemas que puedan surgir debido a la evitación de responsabilidades.",
            howto: "Escucha las preocupaciones y busca soluciones con cada colaborador."
          },
          {
            todo: "Ofrece apoyo personalizado y recursos adicionales para ayudar a los colaboradores a superar cualquier obstáculo en la asunción de responsabilidades.",
            howto: "Asegúrate de que tengan acceso a lo que necesitan para llevar a cabo sus tareas."
          },
          {
            todo: "Anima a cada colaborador a establecer metas de desarrollo relacionadas con la responsabilidad y el liderazgo.",
            howto: "Mantén un seguimiento de su progreso y ofréceles oportunidades para crecer."
          },
          {
            todo: "Continúa manteniendo un canal de comunicación abierto y accesible para que cada colaborador pueda hablar sobre sus desafíos y preocupaciones en relación con las responsabilidades.",
            howto: "Asegúrate de que se sientan cómodos acudiendo a ti con cualquier problema."
          }
        ],
        message: "Estimado líder, tu nivel verde en la evitación de responsabilidades es un testimonio de tu compromiso y habilidades de liderazgo sólidas. Sigue adelante con confianza, liderando con responsabilidad y empatía. Tu ejemplo inspira a tu equipo a dar lo mejor de sí mismos. ¡Sigamos trabajando juntos para alcanzar logros aún mayores!"
      }
    },
    results: {
      red: {
        leader_selfknowledge: [
          {
            todo: "Reflexiona sobre tus objetivos personales y profesionales y cómo están alineados con los objetivos del equipo y la organización.",
            howto: "Pregúntate si tus acciones y decisiones están contribuyendo a los resultados deseados."
          },
          {
            todo: "Evalúa si tienes una comprensión clara de las metas y expectativas del equipo y si las has comunicado adecuadamente.",
            howto: "Verifica que los miembros del equipo entiendan lo que se espera de ellos."
          },
          {
            todo: "Analiza si tiendes a posponer la toma de decisiones importantes debido al temor al fracaso.",
            howto: "Trabaja en la gestión del miedo al fracaso y en la toma de decisiones más efectiva."
          },
          {
            todo: "Investiga si evitas enfrentar resultados negativos o difíciles.",
            howto: "Aprende a abordar los resultados adversos como oportunidades de aprendizaje y mejora."
          },
          {
            todo: "Pregunta a colegas de confianza o un mentor sobre cómo perciben tu enfoque en los resultados y si creen que puedes mejorar en esta área.",
            howto: "Escucha activamente sus opiniones y busca consejos."
          },
          {
            todo: "Examina si te enfocas en el corto plazo en lugar de considerar las implicaciones a largo plazo de tus decisiones.",
            howto: "Desarrolla una perspectiva a largo plazo y considera las consecuencias a largo plazo de tus acciones."
          },
          {
            todo: "Evalúa si te distraes fácilmente de los objetivos o si te desvías frecuentemente de los planes establecidos.",
            howto: "Practica la disciplina y la concentración en torno a las metas clave."
          },
          {
            todo: "Considera la posibilidad de buscar capacitación o desarrollo personal específico en gestión de resultados y toma de decisiones.",
            howto: "Busca cursos, entrenamientos o recursos que te ayuden a fortalecer estas habilidades."
          },
          {
            todo: "Comunica a tu equipo que estás comprometido a mejorar tu enfoque en los resultados y estás dispuesto a trabajar en ello.",
            howto: "Genera confianza al ser transparente sobre tu desarrollo."
          },
          {
            todo: "Establece un plan de acción personalizado con metas claras para mejorar tu atención a los resultados.",
            howto: "Define pasos específicos que te lleven a un mejor desempeño en esta área."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica claramente las metas y objetivos del equipo, y cómo contribuyen al éxito general de la organización.",
            howto: "Asegúrate de que cada miembro del equipo comprenda el propósito y la importancia de su trabajo."
          },
          {
            todo: "Establece expectativas claras sobre la importancia de los resultados y cómo se medirá el éxito.",
            howto: "Alinea los indicadores de rendimiento con los objetivos del equipo."
          },
          {
            todo: "Organiza reuniones regulares para revisar el progreso hacia los resultados y discutir cualquier desafío o barrera.",
            howto: "Fomenta la transparencia en torno al desempeño del equipo."
          },
          {
            todo: "Promueve un ambiente de trabajo donde la mejora continua y el aprendizaje sean valorados.",
            howto: "Anima a los colaboradores a compartir lecciones aprendidas y mejores prácticas."
          },
          {
            todo: "Fomenta una cultura de responsabilidad compartida, donde cada miembro del equipo sienta que sus acciones contribuyen a los resultados.",
            howto: "Reconoce y celebra los logros colectivos."
          },
          {
            todo: "Ofrece apoyo y recursos para ayudar al equipo a alcanzar sus metas, y asegúrate de que tengan acceso a las herramientas necesarias.",
            howto: "Elimina obstáculos que puedan dificultar el logro de resultados."
          },
          {
            todo: "Proporciona retroalimentación constructiva y específica sobre el desempeño en relación con los resultados.",
            howto: "Ayuda a los colaboradores a entender cómo pueden mejorar."
          },
          {
            todo: "Establece un sistema de seguimiento y revisión constante de los indicadores clave de rendimiento y resultados.",
            howto: "Mantén una visión clara de cómo se está avanzando hacia los objetivos."
          },
          {
            todo: "Reconoce y recompensa públicamente los logros del equipo en relación con los resultados.",
            howto: "Alienta la motivación y el compromiso con el éxito."
          },
          {
            todo: "Fomenta un ambiente donde los errores se vean como oportunidades de aprendizaje y no como fracasos.",
            howto: "Promueve la mentalidad de crecimiento y la resiliencia."
          }
        ],
        team_actions: [
          {
            todo: "Mantén reuniones individuales regulares con cada colaborador para revisar su contribución a los resultados y discutir oportunidades de mejora.",
            howto: "Escucha activamente sus preocupaciones y sugerencias."
          },
          {
            todo: "Proporciona retroalimentación personalizada sobre cómo cada colaborador puede mejorar su enfoque en los resultados.",
            howto: "Identifica áreas específicas de mejora y establece metas claras."
          },
          {
            todo: "Apoya el desarrollo de habilidades específicas que ayuden a cada colaborador a alcanzar los resultados esperados.",
            howto: "Ofrece recursos y oportunidades de capacitación pertinentes."
          },
          {
            todo: "Fomenta la confianza de cada colaborador en su capacidad para contribuir a los resultados del equipo.",
            howto: "Reconoce sus logros y brinda refuerzo positivo."
          },
          {
            todo: "Proporciona orientación y apoyo en la toma de decisiones relacionadas con el trabajo y la contribución a los resultados.",
            howto: "Ayuda a los colaboradores a entender cómo sus acciones afectan al logro de objetivos."
          },
          {
            todo: "Anima a cada colaborador a establecer sus propias metas relacionadas con los resultados y ofrece seguimiento regular.",
            howto: "Fomenta la autonomía y la responsabilidad personal."
          },
          {
            todo: "Reconoce públicamente los logros individuales de cada colaborador en relación con los resultados.",
            howto: "Celebra los éxitos y destaca su contribución."
          },
          {
            todo: "Facilita la formación de relaciones sólidas entre los colaboradores para promover la colaboración y el apoyo mutuo en la búsqueda de resultados.",
            howto: "Promueve la comunicación y el trabajo en equipo."
          },
          {
            todo: "Ofrece oportunidades para que cada colaborador se involucre en proyectos clave que impacten directamente en los resultados.",
            howto: "Asigna responsabilidades acordes con sus habilidades y experiencia."
          },
          {
            todo: "Proporciona feedback constructivo sobre cómo cada colaborador está contribuyendo a los resultados y cómo pueden seguir mejorando.",
            howto: "Mantén un diálogo abierto sobre su progreso y desempeño."
          }
        ],
        message: "Querido líder, reconocer que tu atención a los resultados necesita mejoras es un paso valiente hacia el crecimiento. Con determinación y compromiso, puedes fortalecer tu enfoque en el logro de metas y objetivos. Inspira a tu equipo con tu dedicación al éxito y lidera con resolución. Juntos, podemos alcanzar resultados extraordinarios."
      },
      yellow: {
        leader_selfknowledge: [
          {
            todo: "Reflexiona sobre tus objetivos personales y profesionales y cómo están alineados con los objetivos del equipo y la organización.",
            howto: "Pregúntate si tus acciones y decisiones están contribuyendo de manera efectiva a los resultados deseados."
          },
          {
            todo: "Evalúa si tienes una comprensión clara de las metas y expectativas del equipo y si las has comunicado adecuadamente.",
            howto: "Verifica que los miembros del equipo entiendan lo que se espera de ellos en términos de resultados."
          },
          {
            todo: "Analiza si tiendes a posponer la toma de decisiones importantes debido al temor al fracaso.",
            howto: "Trabaja en la gestión del miedo al fracaso y busca tomar decisiones más asertivas."
          },
          {
            todo: "Investiga si evitas enfrentar resultados negativos o difíciles.",
            howto: "Aprende a abordar los resultados adversos como oportunidades de aprendizaje y mejora."
          },
          {
            todo: "Pregunta a colegas de confianza o un mentor sobre cómo perciben tu enfoque en los resultados y si creen que puedes mejorar en esta área.",
            howto: "Escucha activamente sus opiniones y busca consejos."
          },
          {
            todo: "Examina si te enfocas en el corto plazo en lugar de considerar las implicaciones a largo plazo de tus decisiones.",
            howto: "Desarrolla una perspectiva a largo plazo y considera las consecuencias a largo plazo de tus acciones."
          },
          {
            todo: "Evalúa si te distraes fácilmente de los objetivos o si te desvías frecuentemente de los planes establecidos.",
            howto: "Practica la disciplina y la concentración en torno a las metas clave."
          },
          {
            todo: "Considera la posibilidad de buscar capacitación o desarrollo personal específico en gestión de resultados y toma de decisiones.",
            howto: "Busca cursos, entrenamientos o recursos que te ayuden a fortalecer estas habilidades."
          },
          {
            todo: "Comunica a tu equipo que estás comprometido a mejorar tu enfoque en los resultados y estás dispuesto a trabajar en ello.",
            howto: "Genera confianza al ser transparente sobre tu desarrollo y tus esfuerzos por mejorar."
          },
          {
            todo: "Establece un plan de acción personalizado con metas claras para mejorar tu atención a los resultados. ",
            howto: "Define pasos específicos que te lleven a un mejor desempeño en esta área."
          }
        ],
        leader_relation: [
          {
            todo: "Comunica claramente las metas y objetivos del equipo, y cómo contribuyen al éxito general de la organización.",
            howto: "Asegúrate de que cada miembro del equipo comprenda el propósito y la importancia de su trabajo en relación con los resultados."
          },
          {
            todo: "Establece expectativas claras sobre la importancia de los resultados y cómo se medirá el éxito.",
            howto: "Alinea los indicadores de rendimiento con los objetivos del equipo y comunica regularmente el progreso hacia esos objetivos."
          },
          {
            todo: "Organiza reuniones regulares para revisar el progreso hacia los resultados y discutir cualquier desafío o barrera.",
            howto: "Fomenta la transparencia en torno al desempeño del equipo y abre un espacio para el intercambio de ideas y soluciones."
          },
          {
            todo: "Promueve un ambiente de trabajo donde la mejora continua y el aprendizaje sean valorados.",
            howto: "Anima a los colaboradores a compartir lecciones aprendidas y mejores prácticas en relación con la consecución de resultados."
          },
          {
            todo: "Fomenta una cultura de responsabilidad compartida, donde cada miembro del equipo sienta que sus acciones contribuyen a los resultados.",
            howto: "Reconoce y celebra los logros colectivos, destacando el papel de cada individuo en el éxito del equipo."
          },
          {
            todo: "Ofrece apoyo y recursos para ayudar al equipo a alcanzar sus metas, y asegúrate de que tengan acceso a las herramientas necesarias.",
            howto: "Elimina obstáculos que puedan dificultar el logro de resultados y busca formas de mejorar la eficiencia en el trabajo."
          },
          {
            todo: "Proporciona retroalimentación constructiva y específica sobre el desempeño en relación con los resultados.",
            howto: "Ayuda a los colaboradores a entender cómo pueden mejorar y crecer en sus roles."
          },
          {
            todo: "Establece un sistema de seguimiento y revisión constante de los indicadores clave de rendimiento y resultados.",
            howto: "Mantén una visión clara de cómo se está avanzando hacia los objetivos y comunica regularmente el progreso."
          },
          {
            todo: "Reconoce y recompensa públicamente los logros del equipo en relación con los resultados.",
            howto: "Alienta la motivación y el compromiso al valorar y celebrar los éxitos."
          },
          {
            todo: "Fomenta un ambiente donde los errores se vean como oportunidades de aprendizaje y no como fracasos. ",
            howto: "Promueve la mentalidad de crecimiento y la resiliencia al enfrentar desafíos."
          }
        ],
        team_actions: [
          {
            todo: "Mantén reuniones individuales regulares con cada colaborador para revisar su contribución a los resultados y discutir oportunidades de mejora.",
            howto: "Escucha activamente sus preocupaciones y sugerencias y proporciona orientación personalizada."
          },
          {
            todo: "Proporciona retroalimentación personalizada sobre cómo cada colaborador puede mejorar su enfoque en los resultados.",
            howto: "Identifica áreas específicas de mejora y establece metas claras para su desarrollo."
          },
          {
            todo: "Apoya el desarrollo de habilidades específicas que ayuden a cada colaborador a alcanzar los resultados esperados.",
            howto: "Ofrece recursos y oportunidades de capacitación pertinentes para fortalecer sus capacidades."
          },
          {
            todo: "Fomenta la confianza de cada colaborador en su capacidad para contribuir a los resultados del equipo.",
            howto: "Reconoce sus logros y brinda refuerzo positivo para impulsar su confianza y motivación."
          },
          {
            todo: "Proporciona orientación y apoyo en la toma de decisiones relacionadas con el trabajo y la contribución a los resultados.",
            howto: "Ayuda a los colaboradores a entender cómo sus acciones afectan al logro de los objetivos."
          },
          {
            todo: "Anima a cada colaborador a establecer sus propias metas relacionadas con los resultados y ofrece seguimiento regular.",
            howto: "Fomenta la autonomía y la responsabilidad personal, alentando a los colaboradores a asumir un rol activo en el logro de resultados."
          },
          {
            todo: "Reconoce públicamente los logros individuales de cada colaborador en relación con los resultados.",
            howto: "Celebra los éxitos y destaca su contribución al equipo."
          },
          {
            todo: "Facilita la formación de relaciones sólidas entre los colaboradores para promover la colaboración y el apoyo mutuo en la búsqueda de resultados.",
            howto: "Promueve la comunicación y el trabajo en equipo, creando oportunidades para que colaboren en proyectos y compartan conocimientos."
          },
          {
            todo: "Ofrece oportunidades para que cada colaborador se involucre en proyectos clave que impacten directamente en los resultados.",
            howto: "Asigna responsabilidades acordes con sus habilidades y experiencia, permitiéndoles contribuir de manera significativa."
          },
          {
            todo: "Proporciona feedback constructivo sobre cómo cada colaborador está contribuyendo a los resultados y cómo pueden seguir mejorando. ",
            howto: "Mantén un diálogo abierto sobre su progreso y desempeño, y ofréceles el apoyo necesario para su desarrollo continuo."
          }
        ],
        message: "Estimado líder, tu nivel amarillo en la atención a los resultados muestra un sólido compromiso con el crecimiento y la mejora. Sigue adelante con confianza y determinación mientras trabajas en fortalecer tu enfoque en los resultados. Tu liderazgo inspira a tu equipo a alcanzar logros aún mayores. Juntos, continuaremos avanzando hacia el éxito."
      },
      green: {
        leader_selfknowledge: [
          {
            todo: "Sigue reflexionando regularmente sobre tus objetivos personales y profesionales y cómo están alineados con los objetivos del equipo y la organización.",
            howto: "Mantén un diario de metas y logros para asegurarte de que estás en el camino correcto."
          },
          {
            todo: "Continúa evaluando con regularidad si tienes una comprensión clara de las metas y expectativas del equipo y si las has comunicado de manera efectiva.",
            howto: "Solicita retroalimentación de tu equipo para asegurarte de que todos estén en la misma página."
          },
          {
            todo: "Mantén la agilidad en la toma de decisiones importantes y sigue siendo receptivo a nuevas oportunidades.",
            howto: "Fomenta la innovación y la creatividad en tu equipo."
          },
          {
            todo: "Sigue enfrentando de manera proactiva resultados negativos o desafiantes, viéndolos como oportunidades de aprendizaje.",
            howto: "Comparte tus propias experiencias de enfrentar desafíos y cómo los superaste."
          },
          {
            todo: "Continúa buscando consejos de colegas de confianza o un mentor para seguir mejorando.",
            howto: "Establece un grupo de apoyo profesional con el que puedas compartir ideas y desafíos."
          },
          {
            todo: "Mantén una perspectiva a largo plazo al tomar decisiones y considera las implicaciones a largo plazo de tus acciones.",
            howto: "Evalúa regularmente el impacto a largo plazo de tus decisiones estratégicas."
          },
          {
            todo: "Sigue manteniendo un alto nivel de disciplina y concentración en torno a las metas clave.",
            howto: "Practica la gestión eficiente del tiempo y la atención."
          },
          {
            todo: "Continúa buscando oportunidades de capacitación o desarrollo personal para mantener y mejorar tus habilidades en gestión de resultados y toma de decisiones.",
            howto: "Mantente actualizado con cursos y recursos relevantes."
          },
          {
            todo: "Comunica regularmente a tu equipo tu compromiso con la atención a los resultados y tu disposición a apoyarlos en ese camino.",
            howto: "Abre un canal de comunicación abierto sobre la mejora continua."
          },
          {
            todo: "Sigue manteniendo un plan de acción personalizado con metas claras para tu atención a los resultados.",
            howto: "Revisa y ajusta periódicamente tu plan a medida que avanzas en tu carrera."
          }
        ],
        leader_relation: [
          {
            todo: "Continúa comunicando claramente las metas y objetivos del equipo y cómo contribuyen al éxito general de la organización.",
            howto: "Fomenta la alineación constante entre los objetivos individuales y los del equipo."
          },
          {
            todo: "Mantén expectativas claras sobre la importancia de los resultados y cómo se medirá el éxito.",
            howto: "Revisa regularmente los indicadores de rendimiento y celebra los logros."
          },
          {
            todo: "Sigue organizando reuniones regulares para revisar el progreso hacia los resultados y discutir cualquier desafío o barrera.",
            howto: "Promueve una comunicación abierta y colaborativa."
          },
          {
            todo: "Continúa promoviendo un ambiente de trabajo donde la mejora continua y el aprendizaje sean valorados.",
            howto: "Reconoce y premia la innovación y el esfuerzo por mejorar."
          },
          {
            todo: "Mantén una cultura de responsabilidad compartida, donde cada miembro del equipo sienta que sus acciones contribuyen a los resultados.",
            howto: "Incentiva la propiedad personal de los resultados y la colaboración interfuncional."
          },
          {
            todo: "Continúa ofreciendo apoyo y recursos para ayudar al equipo a alcanzar sus metas, y asegúrate de que tengan acceso a las herramientas necesarias.",
            howto: "Elimina obstáculos y facilita el acceso a la formación y tecnología necesarias."
          },
          {
            todo: "Sigue proporcionando retroalimentación constructiva y específica sobre el desempeño en relación con los resultados.",
            howto: "Ayuda a los colaboradores a establecer planes de mejora personalizados."
          },
          {
            todo: "Mantén un sistema de seguimiento y revisión constante de los indicadores clave de rendimiento y resultados.",
            howto: "Fomenta la cultura de revisión y ajuste constante."
          },
          {
            todo: "Continúa reconociendo y recompensando públicamente los logros del equipo en relación con los resultados.",
            howto: "Celebra logros individuales y colectivos de manera regular."
          },
          {
            todo: "Sigue fomentando un ambiente donde los errores se vean como oportunidades de aprendizaje y no como fracasos. ",
            howto: "Promueve la retroalimentación constructiva y la búsqueda de soluciones en lugar de culpar."
          }
        ],
        team_actions: [
          {
            todo: "Continúa manteniendo reuniones individuales regulares con cada colaborador para revisar su contribución a los resultados y discutir oportunidades de mejora.",
            howto: "Escucha activamente sus inquietudes y propuestas."
          },
          {
            todo: "Proporciona retroalimentación personalizada sobre cómo cada colaborador puede mejorar su enfoque en los resultados.",
            howto: "Identifica áreas de desarrollo específicas y trabaja con ellos en planes de mejora."
          },
          {
            todo: "Sigue apoyando el desarrollo de habilidades específicas que ayuden a cada colaborador a alcanzar los resultados esperados.",
            howto: "Proporciona acceso a recursos de formación y desarrollo individualizados."
          },
          {
            todo: "Continúa fomentando la confianza de cada colaborador en su capacidad para contribuir a los resultados del equipo.",
            howto: "Reconoce sus logros de manera regular y brinda refuerzo positivo."
          },
          {
            todo: "Proporciona orientación y apoyo en la toma de decisiones relacionadas con el trabajo y la contribución a los resultados.",
            howto: "Ayuda a los colaboradores a comprender cómo sus acciones afectan a los objetivos."
          },
          {
            todo: "Anima a cada colaborador a establecer sus propias metas relacionadas con los resultados y ofrece seguimiento regular.",
            howto: "Fomenta la autonomía y la responsabilidad personal, alentándolos a asumir la propiedad de sus objetivos."
          },
          {
            todo: "Continúa reconociendo públicamente los logros individuales de cada colaborador en relación con los resultados.",
            howto: "Celebra sus contribuciones y esfuerzos de manera regular."
          },
          {
            todo: "Sigue facilitando la formación de relaciones sólidas entre los colaboradores para promover la colaboración y el apoyo mutuo en la búsqueda de resultados.",
            howto: "Fomenta actividades de construcción de equipos y colaboración."
          },
          {
            todo: "Ofrece oportunidades para que cada colaborador se involucre en proyectos clave que impacten directamente en los resultados.",
            howto: "Asigna responsabilidades de acuerdo con sus fortalezas y habilidades."
          },
          {
            todo: "Continúa proporcionando feedback constructivo sobre cómo cada colaborador está contribuyendo a los resultados y cómo pueden seguir mejorando. ",
            howto: "Mantén una comunicación abierta y orientada hacia el crecimiento individual."
          }
        ],
        message: "Estimado líder, tu nivel verde en la atención a los resultados es un testimonio de tu compromiso y habilidades excepcionales. Sigue liderando con pasión y dedicación, inspirando a tu equipo a alcanzar logros aún mayores. Tu enfoque en los resultados es un faro de éxito para todos nosotros."
      }
    }
  }

  export default recomendations