import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import { createClient } from '@supabase/supabase-js'

import Quiz from './pages/Lencioni/Quiz'
import Share from './pages/Lencioni/Share'

import Companies from './pages/admin/companies'
import CreateCompany from './pages/admin/companies/CreateCompany'
import SlidesAdmin from './pages/admin/slides'

import LencioniAdmin from "./pages/admin/lencioni";

import Report from './pages/admin/lencioni/Report'
import CreateTeam from "./pages/admin/lencioni/CreateTeam";


import Activities from './pages/Activities';
import SendActivity from './pages/Activities/SendActivity';
import CreateActivity from './pages/Activities/CreateActivity';

import CaseStudies from './pages/CaseStudies';

const router = createBrowserRouter([
  {
    path: "/activities",
    element: <Activities/>,
  },
  {
    path: "/activities/send",
    element: <SendActivity/>,
  },
  {
    path: "/masconsultores",
    element: <Navigate to="/activities/send?company=2" replace />
  },
  {
    path: "/casestudies",
    element: <CaseStudies/>,
  },
  // lencioni
  {
    path: "/lencioni",
    element: <Quiz/>,
  },
  {
    path: "/lencioni/share",
    element: <Share/>,
  },
  // admin
  
  {
    path: "/admin/slides",
    element: <SlidesAdmin/>,
  },
  {
    path: "/admin/companies",
    element: <Companies/>,
  },
  {
    path: "/admin/companies/create",
    element: <CreateCompany/>,
  },
  {
    path: "/admin/activities/create",
    element: <CreateActivity/>,
  },
  {
    path: "/admin/lencioni",
    element: <LencioniAdmin />,
  },
  {
    path: "/admin/lencioni/report",
    element: <Report />,
  },
  {
    path: "/admin/lencioni/report",
    element: <Report />,
  },
  {
    path: "/admin/lencioni/create",
    element: <CreateTeam />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
