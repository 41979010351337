import React from "react";
import Sketch from "react-p5";
import { Typography } from "@mui/material";

let x = 50;
let y = 50;

const red = "rgb(200, 35, 38)";
const yellow = "rgb(229,164,0)";
const green = "rgb(37,143,52)";

const determineColor = (score) => {
  if (score >= 0 && score <= 3.24) {
    return red;
  } else if (score >= 3.25 && score <= 3.74) {
    return yellow;
  } else {
    return green;
  }
};

export default ({categories}) => {
  const setup = (p5, canvasParentRef) => {
    const width = window.innerWidth * 0.8; // Set canvas width to 80% of window width
    const height = 800; // Set canvas height to maintain aspect ratio of 720:512
    p5.createCanvas(width, height).parent(canvasParentRef);
    p5.background(0, 0, 0, 0); // Set transparent background
  };

  const drawTrapezoids = (p5, size, categories) => {
    for (let i = 0; i <= 4; i++) {
      let x1 = p5.width / 2 - i * size;
      let x2 = p5.width / 2 + i * size;
      let y = (p5.height - 5 * size * 1.5) / 2 + i * size * 1.5;

      p5.stroke(255)
      p5.strokeWeight(8);

      p5.fill(determineColor(categories[4 -i].score));

      p5.quad(x1, y, x2, y, x2 + size, y + size * 1.5, x1 - size, y + size * 1.5);

      p5.stroke(0)
      p5.strokeWeight(0);
      p5.fill(0,0,0)
    }
  };
  const drawLegend = (p5, height) => {
    const legendX = 400;
    const legendY = 100;

    p5.textSize(18);
    p5.textAlign(p5.LEFT);

    p5.fill(green);
    p5.rect(legendX, legendY, 20, 20);

    p5.fill(0);
    p5.text("Alto (3.75 o más)", legendX + 30, legendY + 15);

    p5.fill(yellow);
    p5.rect(legendX, legendY + 40, 20, 20);

    p5.fill(0);
    p5.text("Medio (3.25 a 3.74)", legendX + 30, legendY + 55);
    
    p5.fill(red);
    p5.rect(legendX, legendY + 80, 20, 20);

    p5.fill(0);
    p5.text("Bajo (menor a 3.24)", legendX + 30, legendY + 95);
  };

  const drawText = (p5, height, categories) => {
    for (let i = 0; i <= 4; i++) {
      let textX = p5.width / 2;
      let textY = (p5.height - 5 * height / 5.2) / 2 + i * height / 5.2 + 80;
      let levelText = "";

      if (i === 4) {
        levelText = "confianza";

        p5.textSize(45);

      } else if (i === 3) {
        levelText = "conflicto";

          p5.textSize(35);

      } else if (i === 2) {
        levelText = "compromiso";
        p5.textSize(28);
      } else if (i === 1) {
        p5.textSize(20);
        levelText = "responsabilidad";


      } else if (i === 0) {
        p5.textSize(12);
        levelText = "resultados";

      }

      p5.fill(255);

      p5.textAlign(p5.CENTER);
      p5.text(levelText, textX, textY);
      p5.fill(200, 35, 38); // Set color to rgb(200, 35, 38)
    }
  };

  const draw = (p5) => {
    p5.clear();
    p5.fill(200, 35, 38);

    const width = window.innerWidth * 0.8; 
    const height = width * 0.71 > 800 ? 800 : width * 0.71; 
    const size = Math.min(((window.innerWidth * 0.8) * 0.71), 800) / 8; 

    drawTrapezoids(p5, size, categories);
    drawText(p5, height, categories);
    drawLegend(p5, height)
  };

  
  if (!categories || categories.length == 0) return;
  return <>
    <Typography variant="h4" color="primary">RESUMEN DEL EQUIPO</Typography>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Sketch setup={setup} draw={draw} />
    </div>
  </>
};



