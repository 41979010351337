import React from 'react';
import { TextField, Typography, Button, Card, CardContent, FormControl, Container, Alert, Modal, Box } from '@mui/material';
import AppBar from '../../components/admin/AppBar'
import { supabase } from '../../supabaseClient';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const Share = () => {
    const [mails, setMails] = React.useState([""]);
    const [name, setName] = React.useState("");
    const [role, setRole] = React.useState("");
    const [mail, setMail] = React.useState("");

    const [teamName, setTeamName] = React.useState("");
    const [maxMembers, setMaxMembers] = React.useState("");

    const [mailsValid, setMailsValid] = React.useState(true)

    const [completed, setCompleted] = React.useState("")

    const validateMails = () => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isValid = mails.every(mail => regex.test(mail));
        setMailsValid(isValid || mails.length == 0);
    };

    const handleMailChange = (e, index) => {
        const updatedMails = [...mails];
        updatedMails[index] = e.target.value;
        setMails(updatedMails);
        validateMails()
    };

    const handleAddMail = () => {
        setMails([...mails, ""]);
    };

    const handleRemoveMail = (index) => {
        const updatedMails = [...mails];
        updatedMails.splice(index, 1);
        setMails(updatedMails);
    };


    const handleSubmit = async () => {
        try {

            if (!teamName || !maxMembers) {
                console.error("Los campos teamName y maxMembers son obligatorios");
                return;
            }

            const { data, error } = await supabase
                .from('lencioni_teams')
                .insert([
                    {
                        name: teamName,
                        max_users: maxMembers,
                        leader_name: name
                    }
                ]).select('*')

            if (error) {
                console.error(error);
            } else {
                console.log(data);
            }

            setCompleted(true);

            // Fetch request to send the fields to the endpoint
            fetch(process.env.REACT_APP_BASE_URL + '/lencioni/email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    role: role,
                    id: data[0].id,
                    sender: name,
                    team: teamName,
                    emails: mails.filter(mail => mail != ""),
                    baseurl:  window.location.origin + "/lencioni"
                })
            })
                .then(response => {
                    if (response.ok) {
                        console.log('Fields sent successfully');
                    } else {
                        console.error('Error sending fields');
                    }
                })
                .catch(error => {
                    console.error('Error sending fields:', error);
                });


        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        validateMails()
    }, [])

    return (
        <Container>
            <AppBar title={"Enviar invitación a equipo"} />
            <Card sx={{ marginTop: '60px' }}>
                <CardContent>
 
                        <Typography>Nombre</Typography>
                        <TextField placeholder='Juan' value={name} onChange={(e) => setName(e.target.value)} />
                 
                        <Typography>Cargo</Typography>
                        <TextField placeholder='Lider' value={role} onChange={(e) => setRole(e.target.value)} />
                   
                        <Typography>Nombre del equipo</Typography>
                        <TextField placeholder='Equipo de ventas' value={teamName} onChange={(e) => setTeamName(e.target.value)} />
                    
                        <Typography>Cantidad de miembros</Typography>
                        <TextField placeholder='5' value={maxMembers} onChange={(e) => setMaxMembers(e.target.value)} />
                 


                    <Alert severity="info">Escribe aquí los correos de los miembros del equipo 😎</Alert>

                    {mails.map((mail, index) => (
                        <div key={index}>
                            <FormControl>

                                <div key={index}>
                                    <FormControl style={{ m: 1, display: 'block', alignItems: 'center', width: 600 }}>

                                        <TextField sx={{ m: 1 }} label="Correo" value={mail} onChange={(e) => handleMailChange(e, index)} />

                                        {index != 0 && <Button
                                            sx={{ m: 1 }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleRemoveMail(index)}
                                            style={{ fontSize: '1.2rem' }}>
                                            -
                                        </Button>}
                                    </FormControl>
                                </div>
                            </FormControl>
                        </div>
                    ))}
                    <Button
                        sx={{ mt: 1, mb: 1 }}
                        disabled={!mailsValid}
                        variant="contained"
                        color="primary"
                        onClick={handleAddMail}
                    >Agregar correo</Button>
                    <br></br>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >Enviar formulario</Button>

                    <Modal open={completed}>

                        <Box sx={{ ...style, width: 400 }}>
                            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h5" style={{ marginBottom: '20px' }}><CheckCircleIcon sx={{color: 'green'}}/>  Formulario enviado</Typography>
                                <Typography variant="body1">Recuerda que el enlace de invitación puede llegar a la carpeta de spam, por lo que es importante recordar a los miembros del equipo que revisen esa carpeta si no encuentran el correo en su bandeja de entrada principal.</Typography>
                            </div>
                        </Box>
                    </Modal>

                </CardContent>
            </Card>
        </Container>
    );
}

export default Share;
